import React, { useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useFetchClient } from '../hooks/ClientHooks';

const StepEdit: React.FC = () => {

  const { guid } = useParams();
  if (!guid) throw Error("Need a client guid");
  const lC_GUID = guid;
  const getClientMutation = useFetchClient(lC_GUID);
  getClientMutation.mutate(lC_GUID);

  return (<></>);
};

export default StepEdit;