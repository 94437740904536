import { CheckboxType } from '../interface'

function Checkbox({
    name,
    text,
    required,
    onChange,
    checked = false,
    disabled=false
  }: CheckboxType) {
  return (
    <div className="form-checkbox-group">
      
    <input
      type="checkbox"
      className="form_input"
      onChange={onChange}
      placeholder=""
      name={name}
      checked={checked}
      disabled={disabled}
    />
    <label htmlFor={name} className="form_label">
      {text}
      {required && <span className="required">*</span>}
    </label>
  </div>
  )
}

export default Checkbox