export interface ReadContextDto {
    url: string
}


class ReadContextDtoRequest {
    url: string;

    constructor(props: ReadContextDto) {
        this.url = props.url;
    }

}


export default ReadContextDtoRequest;