import React, { useEffect } from 'react'
import ErrorComponent from '../components/ErrorComponent';
import { useClient } from "../contexts/LivretMainContext";

const Error: React.FC = () => {

  const { setSubStep } = useClient();

  useEffect(() => {
    setSubStep(50);
  }, [setSubStep]);

  return (
    <ErrorComponent
      errorMessage={"Une erreur inconnue est survenue. Veuillez réessayer ultérieurement"}
      displayButton={false} />
  );
};

export default Error;