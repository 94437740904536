import Helper from '../components/Helper'
import BottomNavigation from '../components/BottomNavigation'
import Radio from '../components/Radio2'
import { useEffect, useState } from 'react';
import { monthlyIncome } from '../utils/labels';
import { useClient } from '../contexts/LivretMainContext';
import { validateMandatory } from '../services/ValidateStep9Service';

function Step9() {  
const { client,setClient,setSubStep,mainErrors,setMainErrors } = useClient();
const [canProceed, setCanProceed] = useState(false);
//validation coté back et affichage des messages d'eurreurs
  useEffect(()=>{
    var validMandatory= validateMandatory(client)
    var isValid= Object.values(mainErrors).every(x => x === ""); 
    setCanProceed(!(isValid && validMandatory))
  },[mainErrors]);
 
  // validation coté front on client change
  useEffect(()=>{
    var validMandatory= validateMandatory(client)    
    setCanProceed(!validMandatory);    
  },[client])
  useEffect(() => {
    setSubStep(9)
  }, [setSubStep]);

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Quel est le montant de vos revenus&nbsp;?
            <Helper popup>
              <p>Merci de renseigner la totalité de vos revenus nets avant imposition.</p>
              <p>Exemples : salaire, revenus locatifs.</p>
            </Helper>
          </h2>
          <div className="form-content">
            <Radio
              name="lC_REVENUE_MENSUEL"
              vertical
              data={monthlyIncome}
              value={client.lC_REVENUE_MENSUEL}
              onChange={(val) => setClient({...client,lC_REVENUE_MENSUEL: Number( val)})}
              label="Vos revenus nets mensuels :"
            />
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container">
      </div>      
      <BottomNavigation
          prevDisabled={false}
          nextDisabled={canProceed}          
          prev="/step8"
          next="/step10"
        />
    </>
  )
}

export default Step9
