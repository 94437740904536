import PropTypes from 'prop-types';
import info from '../assets/icons/info.svg';
import justificatif from '../assets/icons/justif.svg';
import { useToggle } from '../hooks/useToggle';

function Helper({
  icon = 'info',
  title = '',
  offset = 0,
  popup = false,
  desktopOnly = false,
  mobileOnly = false,
  children
}) {
  const [active, toggleActive] = useToggle(false);

  return (
    <>
      {!popup ? (
        <div
          className={`helper${active ? ' active' : ''}${desktopOnly ? ' desktop-only' : ''}${mobileOnly ? ' mobile-only' : ''}`}
          style={{ marginTop: offset && !isNaN(parseInt(offset)) ? offset : 0 }}
          onClick={() => toggleActive()}
        >
          <div className='flex align-center'>
            <span className="helper-close" onClick={() => toggleActive()}><i className="pi pi-times" /></span>
            <img className='helper-icon' src={icon === 'info' ? info : justificatif} alt={`icon ${icon === 'info' ? 'info' : 'justificatif'}`} />
            <div className="content">{title && <h3>{title}</h3>}{children}</div>
          </div>
        </div>
      ) : (
        <>
          <div className='helper-popup-icon' onClick={() => toggleActive()}>
            <img className='helper-icon' src={icon === 'info' ? info : justificatif} alt={`icon ${icon === 'info' ? 'info' : 'justificatif'}`} />
          </div>
          {active && (
            <div className="popup-overlay">
              <div className="popup-content">
                <img className='helper-icon' src={icon === 'info' ? info : justificatif} alt={`icon ${icon === 'info' ? 'info' : 'justificatif'}`} />
                <span className="popup-close" onClick={() => toggleActive()}><i className="pi pi-times" /></span>
                {title && <h3>{title}</h3>}
                {children}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

Helper.propTypes = {
  icon: PropTypes.oneOf(['info', 'document']),
  title: PropTypes.string,
  offset: PropTypes.number,
  popup: PropTypes.bool,
  desktopOnly: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default Helper;