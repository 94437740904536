import { Key, useContext, useEffect, useState } from "react";
import Button from "../components/Button";
// import Perso from '../assets/icons/stepper/perso.svg?react'
import { ReactComponent as Perso } from '../assets/icons/stepper/perso.svg';
import { ReactComponent as Situation } from '../assets/icons/stepper/situation.svg';
import { ReactComponent as Justificatifs } from '../assets/icons/stepper/justificatifs.svg';
import { ReactComponent as Epargne } from '../assets/icons/stepper/epargne.svg';
import { ReactComponent as FileUploaded } from '../assets/icons/file-uploaded.svg';
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getFullName, getPhoneNumber, getformattedDate, isNullOrWhiteSpace } from "../utils/string";
import { getMaritalStatus, getEmploymentStatus, getOccupation, getPoliticalExhibition, getMonthlyIncome, getFinancialAsset, getSourceOfFund, civility } from "../utils/labels";
import { useClient } from "../contexts/LivretMainContext";
import { taxOtherCountry } from "../types/taxOtherCountry";
import { getNationality, getCountry } from "../components/types/countries";
import axiosInstance from "../Interceptors/axiosconfig";

function Step13() {
  const { client, setSubStep } = useClient();
  const [extentActive, setExtentActive] = useState(0);
  const navigate = useNavigate()

  const toggleActiveExtend = (index: number) => {
    if (extentActive === index) {
      setExtentActive(0)
    } else {
      setExtentActive(index)
    }
  }

  const generateCertificate = () => {
    axiosInstance.post(`/api/FileUpload/GenerateCerificate?clientGuid=${client.lC_GUID}`).then((resp) => {
    })
  }

  useEffect(() => {
    if(!client.lC_JUMP_EASYCONFORM_STEP){
      generateCertificate();
    }
  }, [])

  useEffect(() => {
    setSubStep(13)
  }, [setSubStep]);

  return (
    <div className="final-container">
      <h2>Récapitulons...</h2>
      <div className="content">
        <div className="cards">
          <div className={`card ${extentActive === 1 ? 'extended' : ''}`} onClick={() => toggleActiveExtend(1)}>
            <div className="card-header">
              <Perso />
              <h2>Vos informations personnelles</h2>
              <span className="icon"><i className="pi pi-chevron-down" /></span>
            </div>
            <div className="card-content">
              <div className="card-box">
                <p>{getFullName(client.lC_CIVILITE, client.lC_PRENOM, client.lC_NOM)}</p>
                <p>{client.lC_CIVILITE === "M" ? 'Né' : "Née"} le {getformattedDate(client.lC_DATE_NAISSANCE as Date)} à {capitalizeFirstLetter(client.lC_VILLE_NAISSANCE)} {!isNullOrWhiteSpace(client.lC_CP_NAISSANCE) ? `(${client.lC_CP_NAISSANCE})` : ""}</p>
                <p>de nationalité {getNationality(client.lC_NATIONALITE)}.</p>
              </div>
              <div className="card-box">
                <p className="title">Vos coordonnées :</p>
                <p>Email : {client.lC_EMAIL}</p>
                <p>Téléphone : {getPhoneNumber(client.lC_TELEPHONE)}</p>
                <p>Adresse : {client.lC_COORD_ADR1}</p>
                <p>{client.lC_COORD_ADR2}</p>
                <p>{client.lC_COORD_CP} {client.lC_COORD_VILLE} </p>
              </div>
              <div className="card-box">
                <p>Vous êtes {getMaritalStatus(client.lC_SITUATION_FAM)}.</p>
              </div>
            </div>
            <div className="card-footer">
              <Button type="transparent" onClick={() => navigate('/step1')}>Modifier</Button>
            </div>
          </div>
          <div className={`card ${extentActive === 2 ? 'extended' : ''}`} onClick={() => toggleActiveExtend(2)}>
            <div className="card-header">
              <Situation />
              <h2>Votre situation et vos finances</h2>
              <span className="icon"><i className="pi pi-chevron-down" /></span>
            </div>
            <div className="card-content">
              <div className="card-box">
                <p className="title">Votre fiscalité :</p>
                {client.lC_EST_RESD_FISCALE_FRANCE && <p>Résidence fiscale en Francse</p>}
                {client.lC_EST_RESD_FISCALE_AUTRES && <>
                  {client.lIVRET_AUTRE_RESID_FISCALES.map((el: taxOtherCountry, i: Key | null | undefined) => <p key={i}>Obligations fiscales en {getCountry(el.arF_PAYS)}</p>)}
                </>}
                {client.lC_EST_CITOYEN_USA && <p>Vous êtes citoyen des Etats-Unis</p>}
                {!client.lC_EST_CITOYEN_USA && <p>Vous n'êtes pas citoyen des Etats-Unis</p>}
              </div>
              <div className="card-box">
                <p><strong>Professionnellement</strong>, vous êtes : </p>
                <p>{getEmploymentStatus(client.lC_SITUATION_PRO)}</p>
                {client.lC_SITUATION_PRO <= 2 && <p>{getOccupation(client.lC_PROFESSION)}</p>}
                <p>{capitalizeFirstLetter(client.lC_INTITULE_POSTE)}</p>
              </div>
              <div className="card-box">
                <p><strong>Politiquement</strong>, {getPoliticalExhibition(client.lC_EST_PPE ?? false)}</p>
              </div>
              <div className="card-box">
                <>{client.lC_EST_RELATION_PPE && <p>Vous êtes une relation de personne politiquement exposée</p>}</>
              </div>
              <div className="card-box">
                <p><strong>Vos revenus mensuels</strong> sont {getMonthlyIncome(client.lC_REVENUE_MENSUEL)}.</p>
              </div>
              <div className="card-box">
                <p><strong>Votre patrimoine financier</strong> est {getFinancialAsset(client.lC_PATRIMOINE_FINANCIER)}.</p>
              </div>
              <div className="card-box">
                <p><strong>Votre patrimoine immobilier</strong> est {getFinancialAsset(client.lC_PATRIMOINE_IMMOBILIER)}.</p>
              </div>
            </div>
            <div className="card-footer">
              <Button type="transparent" onClick={() => navigate('/step6')}>Modifier</Button>
            </div>
          </div>
          <div className={`card ${extentActive === 3 ? 'extended' : ''}`} onClick={() => toggleActiveExtend(3)}>
            <div className="card-header">
              <Epargne />
              <h2>Votre épargne souhaitée</h2>
              <span className="icon"><i className="pi pi-chevron-down" /></span>
            </div>
            <div className="card-content">
              <div className="card-box">
                <p>Votre <strong>premier versement</strong> sera de {client.lC_PPREMIER_VERSEMENT}€.</p>
              </div>
              <div className="card-box">
                <p>La <strong>provenance</strong> des fonds
                  {client.lIVRET_CLIENT_ORIGINES_FONDS.length > 1 ? ' sont ' : ' est '}
                  {client.lIVRET_CLIENT_ORIGINES_FONDS.filter((k) => k.lcoF_ORIGINES_FONDS !== 8).map((el, i: number, arr: string | any[]) => `${getSourceOfFund(el.lcoF_ORIGINES_FONDS)}${i === arr.length - 1 ? '' : ', '}`)}
                  {(client.lIVRET_CLIENT_ORIGINES_FONDS.map(x => x.lcoF_ORIGINES_FONDS)).includes(8) && `${client.lIVRET_CLIENT_ORIGINES_FONDS.length > 1 ? ',' : ''} ${client.lIVRET_CLIENT_ORIGINES_FONDS.filter(x => x.lcoF_ORIGINES_FONDS == 8).map(x => x.lcoF_AUTRES_FONDS)}`}.
                </p>
              </div>
            </div>
            <div className="card-footer">
              <Button type="transparent" onClick={() => navigate('/step11')}>Modifier</Button>
            </div>

          </div>
          <div className={`card ${extentActive === 4 ? 'extended' : ''}`} onClick={() => toggleActiveExtend(4)}>
            <div className="card-header">
              <Justificatifs />
              <h2>Vos pièces justificatives</h2>
              <span className="icon"><i className="pi pi-chevron-down" /></span>
            </div>
            <div className="card-content">
              {client.lIVRET_CLIENT_DOCUMENTS.map((x, index) =>
                <div className="card-box icon">
                  <FileUploaded />
                  <p className="file-name" key={index}>{x.lcD_NOM}</p>
                </div>
              )}

            </div>
            <div className="card-footer">
              <Button type="transparent" onClick={() => navigate('/step12')}>Modifier</Button>
            </div>

          </div>
        </div>
      </div>
      <div className="button-container">
        <Button type="next" onClick={() => navigate('/step14')}>Suivant</Button>
      </div>
    </div>
  )
}

export default Step13
