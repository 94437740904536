import BottomNavigation from '../components/BottomNavigation'
import { useEffect, useState } from 'react'
import Radio from '../components/Radio'
import Input from '../components/Input'
import Select from '../components/Select'
import Button from '../components/Button'
import Helper from '../components/Helper'
import { useClient } from '../contexts/LivretMainContext'
import { taxOtherCountry } from '../types/taxOtherCountry'
import { countries } from '../components/types/countries'
import { motifNif } from '../utils/labels';
import { FormsErros } from '../types/FormsErros'
import { validate, validateMandatory } from '../services/ValidateStep4Service'
import Checkbox from '../components/Checkbox'
import Popup from '../components/Popup'
import infoPrimary from '../assets/icons/info-primary.svg'

interface TaxOtherCountryRow_PropTypes {
  el: taxOtherCountry,
  index: number,
  onChange: any,
  onRemoveClick: any,
  errors: FormsErros
}

function Step6() {
  const { client, setClient, setSubStep, mainErrors, setMainErrors } = useClient();
  const [canProceed, setCanProceed] = useState(false);
  const [redirectionPopupVisible, setIsVisiblePopup] = useState<boolean>(false);
  const [redirectionPopupTitle, setPopupTitle] = useState<string>("");
  const [popupBody, setPopupBody] = useState<string>("");
  const [redirectionUrl, setRedirectionUrl] = useState<string>("");

  let clientTaxUSA = client.lIVRET_AUTRE_RESID_FISCALES.find(x => x.arF_PAYS === "US");

  useEffect(() => {
    const indexedTaxes = client.lIVRET_AUTRE_RESID_FISCALES
      .filter(tax => tax.arF_PAYS !== 'US')
      .map((tax, idx) => ({ ...tax, index: idx }));

    setClient(prevClient => ({
      ...prevClient,
      lIVRET_AUTRE_RESID_FISCALES: [
        ...prevClient.lIVRET_AUTRE_RESID_FISCALES.filter(tax => tax.arF_PAYS === 'US'),
        ...indexedTaxes,
      ]
    }));
  
    if (clientTaxUSA && clientTaxUSA.index !== 3) {
      setClient(prevClient => ({
        ...prevClient,
        lIVRET_AUTRE_RESID_FISCALES: prevClient.lIVRET_AUTRE_RESID_FISCALES.map(tax =>
          tax.arF_PAYS === "US" ? { ...tax, index: 3 } : tax
        )
      }));
    }
  }, []);

  const handleChangeTaxOtherCountryRow = (index: number, key: any, value: any) => {
    const newTaxObligationsOtherCountries = client.lIVRET_AUTRE_RESID_FISCALES.map((el, i) => {
      if (el.index === index) {
        if (key === 'arF_IDENTIFIANT_EXISTE' && !value) {
          return { ...el, [key]: value, arF_IDENTIFIANT_FISCALE: "", arF_MOTIF_NIF: "" };
        }
        return { ...el, [key]: value };
      }
      return el;
    });
    setClient({ ...client, lIVRET_AUTRE_RESID_FISCALES: newTaxObligationsOtherCountries });
  };

  const handleChangeUSCitizen = (index: number, e: any) => {
    if (e) {
      // US Citizen
      if (!clientTaxUSA) {
        const newTaxUSA: taxOtherCountry = {
          index: 3,
          arF_IDENTIFIANT_FISCALE: "",
          arF_PAYS: "US",
          arF_IDENTIFIANT_EXISTE: true,
          arF_MOTIF_NIF: "",
        };
        setClient(prevClient => ({
          ...prevClient,
          lC_EST_CITOYEN_USA: e,
          lIVRET_AUTRE_RESID_FISCALES: [
            ...prevClient.lIVRET_AUTRE_RESID_FISCALES,
            newTaxUSA
          ]
        }));
      }
    } else {
      // NOT US Citizen
      setClient(prevClient => ({
        ...prevClient,
        lC_EST_CITOYEN_USA: e,
        lIVRET_AUTRE_RESID_FISCALES: prevClient.lIVRET_AUTRE_RESID_FISCALES.filter(el => el.index !== 3)
      }));
    }
  }

  const addTaxOtherCountryRow = () => {
    setClient({
      ...client, lIVRET_AUTRE_RESID_FISCALES: [...client.lIVRET_AUTRE_RESID_FISCALES,
      { index: client.lIVRET_AUTRE_RESID_FISCALES.filter(el => el.arF_PAYS !== 'US').length, arF_IDENTIFIANT_FISCALE: "", arF_PAYS: "", arF_IDENTIFIANT_EXISTE: true, arF_MOTIF_NIF: "" }]
    });
  }

  const removeTaxOtherCountryRow = (index: number) => {
    if (index > 0) {
      var others = client.lIVRET_AUTRE_RESID_FISCALES.filter((_, i) => _.index !== index);
      setClient({ ...client, lIVRET_AUTRE_RESID_FISCALES: others });
    };
  }

  const onchangeTaxOtherCountry = (e: any) => {
    if (e) {
      setClient({
        ...client, lIVRET_AUTRE_RESID_FISCALES: [...client.lIVRET_AUTRE_RESID_FISCALES,
        { index: client.lIVRET_AUTRE_RESID_FISCALES.filter(el => el.arF_PAYS !== 'US').length, arF_IDENTIFIANT_FISCALE: "", arF_PAYS: "", arF_IDENTIFIANT_EXISTE: true, arF_MOTIF_NIF: "" }],
        lC_EST_RESD_FISCALE_AUTRES: e
      });
    } else {
      setClient({ ...client, lIVRET_AUTRE_RESID_FISCALES: [], lC_EST_RESD_FISCALE_AUTRES: e });
    }
  }

  function handleTaxCountryChange(e: any): any {
    setClient({ ...client, lC_EST_RESD_FISCALE_FRANCE: e });
    setPopupTitle("Vous n'êtes pas résident fiscal français");
    setPopupBody("Compte tenu de votre situation, vous ne pouvez pas souscrire à notre produit.");
    setRedirectionUrl("https://www.bfm.fr");
    setIsVisiblePopup(!e);
  }

  const RedirectionPopupContent: JSX.Element =
    <div className='flex flex-column align-center gap-30'>
      <strong>{popupBody}</strong>
      <Button type="next"
        onClick={() => {
          window.location.replace(redirectionUrl)
        }}>redirection</Button></div>

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var isValid = Object.values(mainErrors).every(x => x === "");
    setCanProceed(!(isValid && validMandatory))
  }, [mainErrors]);

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var validateModel = validate(client);
    setCanProceed(validateModel.isValid && !validMandatory);
    setMainErrors(validateModel.errors);
  }, [client])

  useEffect(() => {
    setSubStep(6)
  }, [setSubStep]);

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Et fiscalement...</h2>
          <div className='mb-4'>
            <Helper>
              <p>Vous devez disposer d'une adresse fiscale située en France pour poursuivre votre demande.</p>
            </Helper> 
          </div>  
          <div className="form-content">
            <Radio
              label="Votre résidence fiscale est-elle en France ?"
              name="lC_EST_RESD_FISCALE_FRANCE"
              required
              value={client.lC_EST_RESD_FISCALE_FRANCE}
              onChange={(e) => handleTaxCountryChange(e)}
            />
            {mainErrors.TaxResidenceFranceValidator && <span className="text-white radio--error-message">{mainErrors.TaxResidenceFranceValidator}</span>}
            <Radio
              label={
                <span>
                  Avez-vous des obligations fiscales dans un autre pays ?<span className="required"> *</span>
                  <Helper popup>
                    <p>Ces informations sont obligatoires (Normes d'échange automatique de renseignements relatifs aux comptes financiers).</p>
                    <p>Lien <a href="https://bofip.impots.gouv.fr/bofip/10292-PGP.html/identifiant%3DBOI-INT-AEA-20-20200226" target="_blank" rel="noreferrer">ici</a></p>
                  </Helper>
                </span>
              }
              name="lC_EST_RESD_FISCALE_AUTRES"
              required
              value={client.lC_EST_RESD_FISCALE_AUTRES}
              onChange={(e) => onchangeTaxOtherCountry(e)}
            />
            {mainErrors.TaxObligationsOtherCountryValidator && <span className="text-white radio--error-message">{mainErrors.TaxObligationsOtherCountryValidator}</span>}
            {client.lC_EST_RESD_FISCALE_AUTRES && <>
              {client.lIVRET_AUTRE_RESID_FISCALES.filter(el => el.arF_PAYS !== 'US').map((el, index) =>
                <TaxOtherCountryRow el={el} key={index} index={index} errors={mainErrors}
                  onChange={handleChangeTaxOtherCountryRow} onRemoveClick={removeTaxOtherCountryRow} />)}
              {client.lIVRET_AUTRE_RESID_FISCALES.filter(el => el.arF_PAYS !== 'US').length < 3 && <Button type="add" onClick={addTaxOtherCountryRow}>Ajouter un pays</Button>}
            </>}
            <Radio
              label={
                <>
                  En particulier, avez-vous des obligations fiscales envers les États-Unis d'Amérique ?
                  <span className="required"> *</span>
                  <Helper popup>
                    <p>Vous êtes concerné(e) si vous êtes une "US person" au sens de la foi FATCA (Foreign Account Tax Compliance Act), c'est-à-dire une personne qualifiée de contribuable américain au regard de la législation fiscale aux Etats-Unis.</p>
                    <p>Lien <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000030024817" target="_blank" rel="noreferrer">ici</a></p>
                  </Helper>
                </>
              }
              name="lC_EST_CITOYEN_USA"
              required
              value={client.lC_EST_CITOYEN_USA}
              onChange={(e) => handleChangeUSCitizen(3, e)}
            />
            {mainErrors.UsCitizenValidator && <span className="text-white radio--error-message">{mainErrors.UsCitizenValidator}</span>}

            {client.lC_EST_CITOYEN_USA && (
              <div className="form-content">
                <div className="flex flex-row gap-8 flex-sm-column flex-lg-column">
                  <Input
                    name="lC_ID_FISCALE_USA"
                    error={(mainErrors.UsCitizenFiscalIdValidator || !clientTaxUSA?.arF_IDENTIFIANT_FISCALE ? true : false) && clientTaxUSA?.arF_IDENTIFIANT_EXISTE}
                    errorMsg={mainErrors.UsCitizenFiscalIdValidator}
                    valid={(mainErrors.UsCitizenFiscalIdValidator || !clientTaxUSA?.arF_IDENTIFIANT_FISCALE? false : true) || !clientTaxUSA?.arF_IDENTIFIANT_EXISTE}
                    onChange={(e) => handleChangeTaxOtherCountryRow(3, 'arF_IDENTIFIANT_FISCALE', e.target.value)}
                    label="Identifiant fiscal américain"
                    required={!clientTaxUSA?.arF_IDENTIFIANT_EXISTE ? false : true}
                    value={clientTaxUSA?.arF_IDENTIFIANT_FISCALE??""}
                    className='row-sm-10'
                    disabled={!clientTaxUSA?.arF_IDENTIFIANT_EXISTE}
                  />
                  <div className='row-sm-2'></div>
                </div>
                <div className="flex flex-row gap-8 flex-sm-column flex-lg-column">
                  <div className="row-sm-5">
                    <Checkbox
                      name="Identifiant_existe_USA"
                      onChange={(e) => handleChangeTaxOtherCountryRow(3, 'arF_IDENTIFIANT_EXISTE', !e.target.checked)} 
                      checked={!clientTaxUSA?.arF_IDENTIFIANT_EXISTE}
                      text="Je n'ai pas encore d'identifiant"
                    />
                  </div>
                  { !clientTaxUSA?.arF_IDENTIFIANT_EXISTE ? (
                    <Select
                      name="arF_MOTIF_NIF"
                      error={mainErrors.UsCitizenMotifNifValidator || !clientTaxUSA?.arF_MOTIF_NIF && !clientTaxUSA?.arF_IDENTIFIANT_EXISTE ? true : false}
                      errorMsg={mainErrors.UsCitizenMotifNifValidator}
                      valid={mainErrors.UsCitizenMotifNifValidator || !clientTaxUSA?.arF_MOTIF_NIF && !clientTaxUSA?.arF_IDENTIFIANT_EXISTE ? false : true}
                      onChange={(e) => handleChangeTaxOtherCountryRow(3, 'arF_MOTIF_NIF', e.target.value)} 
                      label="Motif"
                      required
                      value={clientTaxUSA?.arF_MOTIF_NIF??""}
                      className="row-sm-5"
                    >
                      <option value=""></option>
                      {Object.entries(motifNif).map(([key, value]) => (
                        <option value={value} key={key}>{value}</option>
                      ))}
                    </Select>
                  ) : (
                    <div className="row-sm-5"></div>
                  )}
                  <div className="row-sm-2"></div>
                </div>
              </div>
            )}
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container">
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed}
        prev='/step5'
        next="/step7"
      />

      <Popup
        title={redirectionPopupTitle}
        content={RedirectionPopupContent}
        visible={redirectionPopupVisible} icon={infoPrimary} useOverlay={false}
        width='480px' height='420px'
        onClose={() => setIsVisiblePopup(false)}
        hasFooter={false} />
    </>
  )
}

const TaxOtherCountryRow: React.FC<TaxOtherCountryRow_PropTypes> = ({ el, index, onChange, errors, onRemoveClick }) => {
  const GetCountryNameError = (index: number) => {
    if (index === 0) {
      return errors.TaxObligationsOtherCountryName0Validator
    }

    if (index === 1) {
      return errors.TaxObligationsOtherCountryName1Validator
    }

    if (index === 2) {
      return errors.TaxObligationsOtherCountryName2Validator
    }
  }

  const GetCountryIdentifierError = (index: number) => {
    if (index === 0) {
      return errors.TaxObligationsOtherCountryIdentifier0Validator
    }

    if (index === 1) {
      return errors.TaxObligationsOtherCountryIdentifier1Validator
    }

    if (index === 2) {
      return errors.TaxObligationsOtherCountryIdentifier2Validator
    }
  }

  const GetMotifNifError = (index: number) => {
    if (index === 0) {
      return errors.TaxMotifNif0Validator
    }

    if (index === 1) {
      return errors.TaxMotifNif1Validator
    }

    if (index === 2) {
      return errors.TaxMotifNif2Validator
    }
  }

  return <>
    <div className="flex flex-row gap-8 flex-sm-column flex-lg-column">
      <Select
        name={`arF_PAYS-${index}`}
        error={GetCountryNameError(index) || !el.arF_PAYS ? true : false}
        errorMsg={GetCountryNameError(index)}
        valid={GetCountryNameError(index) || !el.arF_PAYS ? false : true}
        onChange={(e) => onChange(index, 'arF_PAYS', e.target.value)}
        label="Pays"
        required
        value={el.arF_PAYS}
        className="row-sm-5">
        <option value=""></option>
        {Object.entries(countries.filter(x => x.code != "US" && x.code != "FR")).map(([index, obj]) =>
          <option value={obj.code} key={index}>{obj.name}</option>)}
      </Select>
      <Input
        name={`arF_IDENTIFIANT_FISCALE-${index}`}
        error={(GetCountryIdentifierError(index) || !el.arF_IDENTIFIANT_FISCALE ? true : false) && el.arF_IDENTIFIANT_EXISTE}
        errorMsg={GetCountryIdentifierError(index)}
        valid={(GetCountryIdentifierError(index) || !el.arF_IDENTIFIANT_FISCALE ? false : true) || !el.arF_IDENTIFIANT_EXISTE}
        onChange={(e) => onChange(index, 'arF_IDENTIFIANT_FISCALE', e.target.value)}
        label="Identifiant fiscal"
        required={!el.arF_IDENTIFIANT_EXISTE ? false : true}
        value={el.arF_IDENTIFIANT_FISCALE}
        disabled= {!el.arF_IDENTIFIANT_EXISTE}
        className="row-sm-5" />
      <div className='row-sm-2'>
        {index > 0 && <Button type="remove" onClick={() => onRemoveClick(index)} className="mt-3"></Button>}
      </div>
    </div>
    <div className="flex flex-row gap-8 flex-sm-column flex-lg-column">
      <div className="row-sm-5">
        <Checkbox
          name="Identifiant_existe"
          onChange={(e) => onChange(index, 'arF_IDENTIFIANT_EXISTE', !e.target.checked)}
          checked={!el.arF_IDENTIFIANT_EXISTE}
          text="Je n'ai pas encore d'identifiant"
        />
      </div>
      {!el.arF_IDENTIFIANT_EXISTE ? (
        <Select
          name={`arF_MOTIF_NIF-${index}`}
          error={GetMotifNifError(index) || !el.arF_MOTIF_NIF && !el.arF_IDENTIFIANT_EXISTE ? true : false}
          errorMsg={GetMotifNifError(index)}
          valid={GetMotifNifError(index) || !el.arF_MOTIF_NIF && !el.arF_IDENTIFIANT_EXISTE ? false : true}
          onChange={(e) => onChange(index, 'arF_MOTIF_NIF', e.target.value)}
          label="Motif"
          required
          value={el.arF_MOTIF_NIF??""}
          className="row-sm-5"
        >
          <option value=""></option>
          {Object.entries(motifNif).map(([index, value]) =>
            <option value={value} key={index}>{value}</option>
          )}
        </Select>
      ) : (
        <div className="row-sm-5"></div>
      )}
      <div className="row-sm-2">

      </div>
    </div>
  </>
}

export default Step6
