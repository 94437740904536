import PropTypes from 'prop-types'
import { ReactComponent as ContactIcon} from '../assets/icons/contact.svg?react'

function Button({
    type = 'primary',
    disabled = false,
    onClick,
    children,
    className=''
  }) {

  return (
    <div
    className={`button${type ? ` button-${type}` : ''}${disabled ? ' button-disabled' : ''}${type === 'primary-center' || type === 'secondary-center' ? ' mb-3' : ''}${className}`}
      onClick={() => {
        if (disabled) return
        onClick()
      }}
    >
      { type === 'contact' && <ContactIcon />}
      { type === 'add' && <i className="pi pi-plus" />}
      { type === 'remove' && <i className="pi pi-times" />}
      { type === 'prev' && <i className="pi pi-arrow-left"/>}
      <span>{ children }</span>
      { (type === 'next' || type === 'transparent') && <i className="pi pi-arrow-right"/>}
      { type === 'primary-center' }
      { type === 'secondary-center' }
    </div>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
}

export default Button