import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

function Input({
    name,
    label,
    value,
    onChange,
    OnClick,
    onCopy = false,
    onPaste = false,
    required = false,
    valid = false,
    error = false,
    errorMsg = '',
    className = '',
    optionsList =[],
    IsAutocomplete = false,
    maxLength = '',
    disabled = false,
  }) {

  const inputRef = useRef(null)
  const [showOptions,setShowOptions] = useState(IsAutocomplete);

  const handleBlurInput = (e) => {
    if (!e.target.value || (e.target.value.trim() || '').length === 0) {
      e.target.parentNode.classList.remove('focus')
    } else {
      e.target.parentNode.classList.add('focus')
    }
  }

  const handleFocusInput = (e) => {
    setShowOptions(true);
  }

  const handlePreventAction = (event,onCopy) => {
    if(onCopy){
      event.preventDefault();
    }
  }

  const handleClickLabel = (e) => {
    e.target.parentNode.classList.add('focus')
    inputRef.current.focus()
  }

  const OnClickhandle = (option) =>{
    OnClick(option);
    setShowOptions(false);
  }

  return (
    <>
    {IsAutocomplete && <div className={`form-group ${className}${value && (value ||'').length > 0 ? ' focus' : ''}${error && error.length > 0 ? ' error-message' : ''}`}>
      <input
        ref={inputRef}
        type="text"
        className="form_input"
        onChange={onChange}
        placeholder=""
        name={name}
        value={value}
        autoComplete="off"
        onBlur={handleBlurInput}
        onFocus={handleFocusInput}
        onCopy={(e) => handlePreventAction(e,onCopy) } 
        onPaste={(e) => handlePreventAction(e,onPaste)}
        maxLength={maxLength}
        disabled={disabled}
      />
      {showOptions && optionsList.length >0 &&
        <ul className='autocomplete-list'>
            {optionsList.map((option,index)=>(
          <li  className='autocomplete-item' key={index} value="option.value" onClick={()=>OnClickhandle(option)}>{option.label}</li>
        ))}
        </ul>
      }
      <label htmlFor={name} className="form_label" onClick={handleClickLabel}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      {valid && <span className="form_input--valid"><i className="pi pi-check" style={{color: '#1AE451'}}/></span>}
      {error && <span className="form_input--error"><i className="pi pi-times" style={{color: '#DC4D57'}}/></span>}
      {error && <span className="form_input--error-message text-white" style={{visibility:'visible'}}>{errorMsg}</span>} 
    </div>}
    {!IsAutocomplete &&     
    <div className={`form-group ${className}${value && (value ||'').length > 0 ? ' focus' : ''}${error && error.length > 0 ? ' error-message' : ''}`}>
      <input
        ref={inputRef}
        type="text"
        className="form_input"
        onChange={onChange}
        placeholder=""
        name={name}
        value={value}
        autoComplete="off"
        onBlur={handleBlurInput}
        onCopy={(e) => handlePreventAction(e,onCopy) } 
        onPaste={(e) => handlePreventAction(e,onPaste)}
        disabled={disabled}
      />
      <label htmlFor={name} className="form_label" onClick={handleClickLabel}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      {valid && <span className="form_input--valid"><i className="pi pi-check" style={{color: '#1AE451'}}/></span>}
      {error && <span className="form_input--error"><i className="pi pi-times" style={{color: '#DC4D57'}}/></span>}
      {error && <span className="form_input--error-message text-white" style={{visibility:'visible'}}>{errorMsg}</span>} 
      </div>
    }
    </>
    
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  OnClick: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  valid: PropTypes.bool,
  className: PropTypes.string,
  showOptionsList: PropTypes.bool,
  optionsList: PropTypes.object,
  disabled: PropTypes.bool,
}

export default Input