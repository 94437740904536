import Helper from '../components/Helper'
import Input from '../components/Input'
import BottomNavigation from '../components/BottomNavigation'
import Radio from '../components/Radio'
import { useEffect, useState } from 'react'
import { useClient } from '../contexts/LivretMainContext'
import { validate, validateMandatory } from '../services/ValidateStep3Service'
import axiosInstance from '../Interceptors/axiosconfig'

function Step3() {
  const [optionsList, setOptionsList] = useState([])
  const { client, setClient, setSubStep, mainErrors, setMainErrors } = useClient();
  const [canProceed, setCanProceed] = useState(false);
  const onChange = async (e: any) => {
    setClient({ ...client, lC_COORD_CP: e.target.value });
    var filtredOptions = [];
    if (e.target.value.length > 1) {
      const response = await axiosInstance.get(`/Hexapost/SearchMunicipalitiesByValue?search=` + e.target.value)
      filtredOptions = response.data.map((x: { libelle: any; code: any }) => ({
        label: x.libelle,
        value: x.code,
        labelCity: x.libelle
      }))
      setOptionsList(filtredOptions);
    } else {
      setOptionsList([]);
    }
  };
  const onButtonClick = () => {
    const pdfUrl = "Attestation_hebergement_BFM.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Attestation.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const OnClickHandle = (selectedOpt: any) => {
    setClient({ ...client, lC_COORD_CP: selectedOpt.value, lC_COORD_VILLE: selectedOpt.labelCity })
  }

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var isValid = Object.values(mainErrors).every(x => x === "");
    setCanProceed(!(isValid && validMandatory))
  }, [mainErrors]);

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var validateModel = validate(client);
    setCanProceed(validateModel.isValid && !validMandatory);
    setMainErrors(validateModel.errors);
  }, [client])
  useEffect(() => {
    setSubStep(3)
  }, [setSubStep]);
  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Quelle est votre adresse&nbsp;?</h2>
          <div className="form-content">
            <Input
              name="lC_COORD_ADR1"
              error={mainErrors.Address1Validator || !client.lC_COORD_ADR1 ? true : false}
              errorMsg={mainErrors.Address1Validator}
              valid={mainErrors.Address1Validator || !client.lC_COORD_ADR1 ? false : true}
              onChange={(e) => setClient({ ...client, lC_COORD_ADR1: e.target.value })}
              label="Numéro et nom de rue"
              required
              value={client.lC_COORD_ADR1}
            />
            <Input
              name="lC_COORD_ADR2"
              error={mainErrors.Address2Validator ? true : false}
              errorMsg={mainErrors.Address2Validator}
              valid={mainErrors.Address2Validator ? false : true}
              onChange={(e) => setClient({ ...client, lC_COORD_ADR2: e.target.value })}
              label="Complément d'adresse"
              value={client.lC_COORD_ADR2}
            />
            <div className="flex flex-row gap-8">
              <Input
                name="lC_COORD_CP"
                error={mainErrors.ZipCodeValidator || !client.lC_COORD_CP ? true : false}
                errorMsg={mainErrors.ZipCodeValidator}
                valid={mainErrors.ZipCodeValidator || !client.lC_COORD_CP ? false : true}
                onChange={(e) => onChange(e)}
                OnClick={OnClickHandle}
                label="Code postal"
                required
                optionsList={optionsList}
                IsAutocomplete={true}
                value={client.lC_COORD_CP}
                maxLength='5'
              />
              <Input
                name="lC_COORD_VILLE"
                error={mainErrors.CityValidator || !client.lC_COORD_VILLE ? true : false}
                errorMsg={mainErrors.CityValidator}
                valid={mainErrors.CityValidator || !client.lC_COORD_VILLE ? false : true}
                onChange={(e) => setClient({ ...client, lC_COORD_VILLE: e.target.value })}
                label="Ville"
                required
                value={client.lC_COORD_VILLE}
              />
            </ div>
            <Radio
              label="Êtes-vous hébergé par un tiers ?"
              name="lC_CIVILITE"
              required
              value={client.lC_HEBERGEMENT_TIERS}
              onChange={(e) => setClient({ ...client, lC_HEBERGEMENT_TIERS: e })}
            />
            {mainErrors.HostedByThirdPartyValidator && <span className="text-white radio--error-message">{mainErrors.HostedByThirdPartyValidator}</span>}
            {client.lC_HEBERGEMENT_TIERS ?
              <Helper title="Vous êtes hébergé par un tiers ?" icon="document" desktopOnly>
                <p>Si vous êtes hébergé(e) par un tiers, vous devrez nous fournir les justificatifs suivants :</p>
                <ul>
                  <li onClick={onButtonClick}>une Attestation d'hébergement<br />
                    Celle-ci devra être renseignée et signée manuellement par l'hébergeur et vous-même.</li>
                  <li>une pièce d&apos;identité de votre hébergeur</li>
                  <li>un justificatif de domicile à votre nom</li>
                  
                </ul>
              </Helper> : ''}
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container">
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed}
        prev='/step2'
        next="/step4"
      />
    </>
  )
}

export default Step3
