import { useEffect, useState } from 'react'
import { CheckboxChangeEvent, CheckboxChoiceType, CheckboxMultipleType } from '../interface'
import { capitalizeFirstLetter } from '../utils/string'

function CheckboxMultiple({
    label,
    name,
    data = {},
    required,
    onChange,
    value = []
  }: CheckboxMultipleType) {
  const [choices, setChoices] = useState<string[]>([])

  const handleChoiceChange = (e: CheckboxChangeEvent) => {
    let newChoices: string[] = [...choices]
    if (e.target.checked) {
      newChoices.push(e.target.value)
    } else {
      newChoices = newChoices.filter(choice => choice !== e.target.value)
    }
    setChoices(newChoices)
    onChange(newChoices)
  }

  useEffect(() => {
    setChoices([...value])
  }, [value]);
  return (
    <div className="form-checkbox-multiple-group">
      { label && 
        <label className="form-checkbox-multiple_label">
          <span className="label">{label}{required && <span className="required"> *</span>}</span>
        </label>
      }
      <div className="form-checkbox-multiple_rows">
        {Object.entries(data).map((val, index) => <CheckboxChoice data={val} key={index} checked={value.includes(val[0])} onChange={handleChoiceChange} name={name} />)}
      </div>
  </div>
  )
}

const CheckboxChoice = ({ data: [ key, value], onChange, name, checked }: CheckboxChoiceType) => {
  return (
    <div className="form-checkbox-multiple_row">
      <input
        type="checkbox"
        className="form_input"
        onChange={onChange}
        name={name}
        value={key}
        checked={checked}
      />
      <label htmlFor={name} className="form_label">{capitalizeFirstLetter(value)}</label>
    </div>
  )
}

export default CheckboxMultiple