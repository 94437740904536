import { useContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptors/axiosconfig";
import pdf from "../assets/icons/pdf.svg"

export interface DownloadOfferType {
    token: string;
}

const DownloadOffer: React.FC<DownloadOfferType> = ({ token }) => {

    const [file, setFile] = useState<string | undefined>();

    useEffect(() => {
        handleDownloadSignedContract()
    }, [file]);


    const handleDownloadSignedOffreLivretOnClick = async () => {
        const response = await axiosInstance.post(`/ElectronicSignature/GetSignedLivretContract?token=${token}`).then((resp) => resp.data);
        setFile(response);
    }

    function handleDownloadSignedContract() {
        if (file) {
            const mimType = 'application/pdf';
            var blob = base64ToBlob(file, mimType);
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "BFM_Offre_Livret_Signee.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
        setFile(undefined);
    }

    function base64ToBlob(file: string, mimtype: string) {
        const byteCharacters = atob(file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimtype });
    }

    function handleDownloadRetractionDocument() {
        const pdfUrl = "Bordereau_de_rétractation.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Bordereau_de_rétractation.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <div className="final-container">
                <div className="se-document">
                    <h2>Merci de votre confiance !</h2>
                    <p>Nous vous remercions de votre confiance. Vous trouverez ci-dessous votre dossier de souscription signé. Téléchargez-le et conservez-le.
                        Vous pourrez également le retrouver dans votre espace client lorsque vous l'aurez activé.</p>
                    <div className="pdf-section">
                        <a onClick={() => handleDownloadSignedOffreLivretOnClick()}>
                            <img src={pdf} alt="BFM_Offre_Livret_Signee.pdf" className="pdf-icon" />
                            <p>BFM_Offre_Livret_Signee.pdf</p>
                        </a>
                    </div>
                    <div className="instructions">
                        <p>Quelles sont les prochaines étapes ?</p>
                        <p>1. Après l'analyse de votre dossier par nos services, vous recevrez par email votre RIB.</p>
                        <p>2. À réception, vous aurez un délais de 10 jours ouvrés pour effectuer votre versement. Sans ce versement, nous clôturerons votre demande.
                        Ce virement doit être réalisé à partir du compte à vue dont vous nous avez communiqué le RIB lors de votre demande.</p>
                    </div>
                    <div className="retraction-section">
                        <p>Nous vous rappelons que vous avez un délai de 14 jours calendaires à compter de la signature de votre Contrat pour vous rétracter.
                            Vous trouverez ci-dessous le bordereau à utiliser et à nous envoyer par courrier ou via votre Espace Client que vous aurez au préalable activé.</p>
                        <div className="pdf-section">
                            <a onClick={() => handleDownloadRetractionDocument()}>
                                <img src={pdf} alt="Bordereau_de_rétractation.pdf" className="pdf-icon" />
                                <p>Bordereau_de_rétractation.pdf</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadOffer
