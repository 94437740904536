import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { isNullOrWhiteSpace, validateZipCode } from "../utils/string";

export const validateMandatory = (client: Client): boolean => {
    var tmp = !(client.lC_PATRIMOINE_IMMOBILIER===99) && !(client.lC_PATRIMOINE_IMMOBILIER===99);
    return tmp;
}

 
 
