import { useEffect, useState } from 'react';
import BottomNavigation from '../components/BottomNavigation'
import Radio from '../components/Radio2'
import { useClient } from '../contexts/LivretMainContext'
import { maritalStatus } from '../utils/labels';
import { validateMandatory } from '../services/ValidateStep5bisService';

function Step5() {
  const { client, setClient, setSubStep, mainErrors, setMainErrors } = useClient();
  const [canProceed, setCanProceed] = useState(false);

  useEffect(()=>{
    var validMandatory= validateMandatory(client)
    var isValid= Object.values(mainErrors).every(x => x === ""); 
    setCanProceed(!(isValid && validMandatory))
  },[mainErrors]);
 
  useEffect(()=>{
    var validMandatory= validateMandatory(client)    
    setCanProceed(!validMandatory);    
  },[client])
  
  useEffect(() => {
    setSubStep(5)
  }, [setSubStep]);

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Parlons de votre situation familiale...</h2>
          <div className="form-content">
            <Radio
              name="lC_SITUATION_FAM"
              verticalWrap
              label="Actuellement, vous êtes :"
              value={client.lC_SITUATION_FAM}
              data={maritalStatus}
              onChange={(val) => setClient({...client,lC_SITUATION_FAM: Number( val)})}
            />
          </div>
        </div>
      </div>
      <div className="helper-container">
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed} 
        prev="/step4"
        next="/step6"
      />
    </>
  )
}

export default Step5
