import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { isNullOrWhiteSpace, validateZipCode } from "../utils/string";

export const validateMandatory = (client: Client): boolean => {
    return !(client.lC_REVENUE_MENSUEL===99);
}

 
 
