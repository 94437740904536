import moment from 'moment';
import { civility as civilityLabels } from './labels'

export const isNullOrWhiteSpace = (str) => {
  return (str?.trim() === "" || str === null && str === undefined)
}

export const validateZipCode = (zipCode)  => {
  const regx = /^(\d{5})?$/;
  return regx.test(zipCode);
}

export const validateNumberOnly= (str)  => {
  const regx = /^(\d)*$/;
  return regx.test(str);
}

export const validateLettersOnly= (str)  => {
  const regx = /^[A-Za-zÀ-ÖØ-öø-ÿ]+( [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/;
  return regx.test(str);
}

export const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getFullName = (civility, firstName, lastName) => {
  const c = civility && civility.length > 0 ? capitalizeFirstLetter(civilityLabels[civility]) : ''
  const f = firstName && firstName.length > 0 ? capitalizeFirstLetter(firstName) : ''
  const l = lastName && lastName.length > 0 ? lastName.toUpperCase() : ''
  return `${c} ${f} ${l}`
}

export const getformattedDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const getPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return ''
  return phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
}