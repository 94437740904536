import PropTypes from 'prop-types'
import Helper from '../components/Helper'
import BottomNavigation from '../components/BottomNavigation'
import Radio from '../components/Radio'
import { useEffect, useState } from 'react';
import Input from '../components/Input';
import Calendar from '../components/Calendar';
import { useClient } from '../contexts/LivretMainContext';
import { exposedPerson } from '../types/exposedPerson';
import Select from '../components/Select';
import { countries } from '../components/types/countries';
import { detailsPPE } from '../types/detailsPPE';
import { validate, validateMandatory } from '../services/ValidateStep8Service';
import { FormsErros } from '../types/FormsErros';
import ReactSelect, { StylesConfig, components } from 'react-select';
import axiosInstance from '../Interceptors/axiosconfig';
import { natureRelation } from '../utils/labels';

function Step8() {
  const { client, setClient, mainErrors, setMainErrors, setSubStep } = useClient();
  const [canProceed, setCanProceed] = useState(false);
  const [exposedPerson, setExposedPerson] = useState<exposedPerson>({
    rPPE_NOM: "",
    rPPE_PRENOM: "",
    rPPE_DATE_NAISSANCE: null,
    rPPE_FONCTION: "",
    rPPE_ORGANISME: "",
    rPPE_DATE_DEBUT: null,
    rPPE_DATE_FIN: null,
    rPPE_PAYS: "",
    rPPE_NATURE_RELATION: "",
  });
  const [detailsPpe, setDetailsPpe] = useState<detailsPPE>({
    lC_PPE_FONCTION: "",
    lC_PPE_ORGANISME: "",
    lC_PPE_DATE_DEBUT: null,
    lC_PPE_DATE_FIN: null,
    lC_PPE_PAYS: "",
  });

  const [optionsList1, setOptionsList1] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState();
  const [optionsList2, setOptionsList2] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState();

  const handleItemChange = (key: string, value: any) => {
    setClient({ ...client, [key]: value });
  }

  const onExposedPersonChange = (e: any) => {
    if (e) {
      setClient({ ...client, rELATION_PPE: exposedPerson, lC_EST_RELATION_PPE: e })
    } else {
      setClient({ ...client, rELATION_PPE: null, lC_EST_RELATION_PPE: e })
    }
  }

  const onDetailsPpeChange = (e: any) => {
    if (e) {
      setClient({ ...client, lIVRET_CLIENT_PPE: detailsPpe, lC_ROLE_IMPORTANT_DERNIERS_MOIS: e })
    } else {
      setClient({ ...client, lIVRET_CLIENT_PPE: null, lC_ROLE_IMPORTANT_DERNIERS_MOIS: e })
    }
  }

  const loadMotifPpe = async () => {
    var options = [];
    const response = await axiosInstance.get(`/MotifPpe/GetAll`);
    options = response.data.map((x: { mppE_LIBELLE: any; mppE_CODE: any }) => ({
      label: x.mppE_LIBELLE,
      value: x.mppE_CODE,
    }));
    return options;
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const options = await loadMotifPpe();
        setOptionsList1(options);
        setOptionsList2(options);
      } catch (error) {
        console.error('Erreur chargement motifs PPE', error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var isValid = Object.values(mainErrors).every(x => x === "");
    setCanProceed(!(isValid && validMandatory))
  }, [mainErrors]);

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var validateModel = validate(client);
    setCanProceed(validateModel.isValid && !validMandatory);
    setMainErrors(validateModel.errors);
  }, [client])

  useEffect(() => {
    setSubStep(8);
  }, [setSubStep]);

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Êtes-vous politiquement exposé(e)&nbsp;?
            <Helper popup>
              <p>Vous êtes concerné(e) si vous êtes ou avez été chargé(e) d'une fonction publique importante.</p>
              <p>Exemple : personne qui s'est vu confier une fonction publique importante au sein d'un gouvernement ou d'une organisation internationale. Les proches collaborateurs et les membres de la famille sont également considérés comme des PPE.</p>
              <p>Liste complète des fonctions PPE : <a href="https://www.legifrance.gouv.fr/jorf/article_jo/JORFARTI000047324776" target="_blank" rel="noreferrer">ici</a></p>
            </Helper>
          </h2>
          <div className="form-content">
            <Radio
              name="lC_EST_PPE"
              required={true}
              value={client.lC_EST_PPE}
              label="Êtes-vous personnellement exposé(e) ?"
              onChange={(e) => setClient({ ...client, lC_EST_PPE: e })}
            />
            {client.lC_EST_PPE && <>
              <Radio
                name="lC_ROLE_IMPORTANT_DERNIERS_MOIS"
                required={true}
                value={client.lC_ROLE_IMPORTANT_DERNIERS_MOIS}
                label="Exercez-vous ou avez-vous exercé dans les 12 derniers mois une fonction politique, juridictionnelle ou administrative importante ?"
                onChange={(e) => onDetailsPpeChange(e)}
              />
              {client.lC_ROLE_IMPORTANT_DERNIERS_MOIS
                && <ImportantRoleLast12MonthsDetails
                  details={client.lIVRET_CLIENT_PPE != null ? client.lIVRET_CLIENT_PPE : detailsPpe}
                  onChange={(key, value) => handleItemChange(key, value)}
                  errors={mainErrors}
                  optionsList={optionsList1}
                  selectedOption={selectedOption1}
                  setSelectedOption={setSelectedOption1}
                />
              }
            </>
            }
            <Radio
              name="lC_EST_RELATION_PPE"
              required={true}
              value={client.lC_EST_RELATION_PPE}
              label="Êtes-vous en relation avec une personne exposée ?"
              onChange={(e) => onExposedPersonChange(e)}
            />
            {client.lC_EST_RELATION_PPE &&
              <ExposedPerson
                person={client.rELATION_PPE != null ? client.rELATION_PPE : exposedPerson}
                onChange={(key, value) => handleItemChange(key, value)}
                errors={mainErrors}
                optionsList={optionsList2}
                selectedOption={selectedOption2}
                setSelectedOption={setSelectedOption2}
              />
            }
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container">
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed}
        prev='/step7'
        next="/step9"
      />
    </>
  )
}

const CustomPlacehoder = (props: any) => (
  <components.Placeholder {...props}>
    {props.children}<span className="required">*</span>
  </components.Placeholder>
);

const CustomValue = (props: any) => (
  <components.SingleValue {...props}>
    <div style={{ marginLeft: '8px' }}>
      {props.children}
    </div>
  </components.SingleValue>
);

const CustomOption = (props: any) => (
  <components.Option {...props}>
    <div style={{ marginLeft: '8px' }}>
      {props.children}
    </div>
  </components.Option>
);

const CustomDropdownIndicator = (props: any) => {
  const color = props.selectProps.value ? '#1AE451' : '#DC4D57';
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </components.DropdownIndicator>
  );
};

const ImportantRoleLast12MonthsDetails = ({ details, onChange, errors, optionsList, selectedOption, setSelectedOption }:
  { details: detailsPPE, onChange: (key: string, value: any) => void, errors: FormsErros, optionsList: any[], selectedOption: any, setSelectedOption: (option: any) => void }) => {
  const [detailsPpe, setDetailsPpe] = useState(details);

  const customStyles: StylesConfig<any, false> = {
    control: (provided) => ({
      ...provided, height: '59px'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#DC4D57',
      padding: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    })
  }

  const onChangeDetailsPpe = (key: string, value: string) => {
    setDetailsPpe({ ...detailsPpe, [key]: value });
  }

  useEffect(() => {
    setSelectedOption(optionsList.find(x => x.value === details.lC_PPE_FONCTION));
  }, [optionsList, details.lC_PPE_FONCTION]);

  useEffect(() => {
    onChange("lIVRET_CLIENT_PPE", detailsPpe);
  }, [detailsPpe])

  const onChangelist = async (e: any) => {
    setSelectedOption(e);
    onChangeDetailsPpe('lC_PPE_FONCTION', e.value);
  }

  return (
    <>
      <ReactSelect
        components={{ Placeholder: CustomPlacehoder, SingleValue: CustomValue, Option: CustomOption, DropdownIndicator: CustomDropdownIndicator }}
        placeholder='Fonction occupée'
        styles={customStyles}
        options={optionsList}
        onChange={onChangelist}
        value={selectedOption}
      ></ReactSelect>
      <Input
        name="lC_PPE_ORGANISME"
        error={errors.OrganizationPPEValidator || !details.lC_PPE_ORGANISME ? true : false}
        errorMsg={errors.OrganizationPPEValidator}
        valid={errors.OrganizationPPEValidator || !details.lC_PPE_ORGANISME ? false : true}
        value={details.lC_PPE_ORGANISME}
        onChange={(e) => onChangeDetailsPpe('lC_PPE_ORGANISME', e.target.value)}
        label="Organisme/entité"
        required
      />
      <div className="flex flex-row gap-8">
        <Calendar
          name="lC_PPE_DATE_DEBUT"
          error={errors.StartDatePPEValidator || !details.lC_PPE_DATE_DEBUT ? true : false}
          errorMsg={errors.StartDatePPEValidator}
          valid={errors.StartDatePPEValidator || !details.lC_PPE_DATE_DEBUT ? false : true}
          onChange={(e) => onChangeDetailsPpe('lC_PPE_DATE_DEBUT', (e.target as unknown as HTMLInputElement).value)}
          label="Date de début"
          className="row-7 row-sm-6"
          value={details.lC_PPE_DATE_DEBUT ? new Date(details.lC_PPE_DATE_DEBUT) : null}
          required
        />
        <Calendar
          name="lC_PPE_DATE_FIN"
          error={errors.EndDatePPEValidator || !details.lC_PPE_DATE_FIN ? true : false}
          errorMsg={errors.EndDatePPEValidator}
          valid={errors.EndDatePPEValidator || !details.lC_PPE_DATE_FIN ? false : true}
          onChange={(e) => onChangeDetailsPpe('lC_PPE_DATE_FIN', (e.target as unknown as HTMLInputElement).value)}
          label="Date de fin"
          className="row-7 row-sm-6"
          value={details.lC_PPE_DATE_FIN ? new Date(details.lC_PPE_DATE_FIN) : null}
          required
        />
      </div>
      <Select
        name="lC_PPE_PAYS"
        error={errors.CountryPPEValidator || !details.lC_PPE_PAYS ? true : false}
        errorMsg={errors.CountryPPEValidator}
        valid={errors.CountryPPEValidator || !details.lC_PPE_PAYS ? false : true}
        label="Pays concerné"
        required
        value={details.lC_PPE_PAYS}
        onChange={(e) => onChangeDetailsPpe('lC_PPE_PAYS', e.target.value)}
      >
        <option value="-1"></option>
        {Object.entries(countries).map(([index, obj]) =>
          <option value={obj.code} key={index}>{obj.name}</option>
        )}
      </Select>
    </>
  )
}

const ExposedPerson = ({ person, onChange, errors, optionsList, selectedOption, setSelectedOption }:
  { person: exposedPerson, onChange: (key: string, value: exposedPerson) => void, errors: FormsErros, optionsList: any[], selectedOption: any, setSelectedOption: (option: any) => void }) => {
  const [exposedperson, setExposedPerson] = useState(person);

  const customStyles: StylesConfig<any, false> = {
    control: (provided) => ({
      ...provided, height: '59px'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#DC4D57',
      padding: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    })
  }

  const onChangeExposedPerson = (key: string, value: string) => {
    setExposedPerson({ ...exposedperson, [key]: value });
  }

  useEffect(() => {
    setSelectedOption(optionsList.find(x => x.value === person.rPPE_FONCTION));
  }, [optionsList, person.rPPE_FONCTION]);

  useEffect(() => {
    onChange("rELATION_PPE", exposedperson);
  }, [exposedperson]);

  const onChangelist = (e: any) => {
    setSelectedOption(e);
    onChangeExposedPerson('rPPE_FONCTION', e.value);
  }

  return (
    <>
      <Input
        name="rPPE_NOM"
        error={errors.PartnerLastNameValidator || !person.rPPE_NOM ? true : false}
        errorMsg={errors.PartnerLastNameValidator}
        valid={errors.PartnerLastNameValidator || !person.rPPE_NOM ? false : true}
        value={person.rPPE_NOM}
        onChange={(e) => onChangeExposedPerson('rPPE_NOM', e.target.value)}
        label="Nom de la personne exposée"
        required
      />
      <Input
        name="rPPE_PRENOM"
        error={errors.PartnerFirstNamePPEValidator || !person.rPPE_PRENOM ? true : false}
        errorMsg={errors.PartnerFirstNamePPEValidator}
        valid={errors.PartnerFirstNamePPEValidator || !person.rPPE_PRENOM ? false : true}
        value={person.rPPE_PRENOM}
        onChange={(e) => onChangeExposedPerson('rPPE_PRENOM', e.target.value)}
        label="Prénom de la personne exposée"
        required
      />
      <Calendar
        name="rPPE_DATE_NAISSANCE"
        onChange={(e) => onChangeExposedPerson('rPPE_DATE_NAISSANCE', (e.target as unknown as HTMLInputElement).value)}
        label="Date de naissance"
        value={person.rPPE_DATE_NAISSANCE ? new Date(person.rPPE_DATE_NAISSANCE) : null}
        required={false}
      />
      <ReactSelect
        components={{ Placeholder: CustomPlacehoder, SingleValue: CustomValue, Option: CustomOption, DropdownIndicator: CustomDropdownIndicator }}
        placeholder='Fonction occupée'
        styles={customStyles}
        options={optionsList}
        onChange={onChangelist}
        value={selectedOption}
      ></ReactSelect>
      <Input
        name="rPPE_ORGANISME"
        error={errors.OrganizationPartnerPPEValidator || !person.rPPE_ORGANISME ? true : false}
        errorMsg={errors.OrganizationPartnerPPEValidator}
        valid={errors.OrganizationPartnerPPEValidator || !person.rPPE_ORGANISME ? false : true}
        value={person.rPPE_ORGANISME}
        onChange={(e) => onChangeExposedPerson('rPPE_ORGANISME', e.target.value)}
        label="Organisme/entité"
        required
      />
      <div className="flex flex-row gap-8">
        <Calendar
          name="rPPE_DATE_DEBUT"
          error={errors.StartDateRPPEValidator || !person.rPPE_DATE_DEBUT ? true : false}
          errorMsg={errors.StartDateRPPEValidator}
          valid={errors.StartDateRPPEValidator || !person.rPPE_DATE_DEBUT ? false : true}
          onChange={(e) => onChangeExposedPerson('rPPE_DATE_DEBUT', (e.target as unknown as HTMLInputElement).value)}
          label="Date de début"
          className="row-7 row-sm-6"
          value={person.rPPE_DATE_DEBUT ? new Date(person.rPPE_DATE_DEBUT) : null}
          required
        />
        <Calendar
          name="rPPE_DATE_FIN"
          error={errors.EndDateRPPEValidator || !person.rPPE_DATE_FIN ? true : false}
          errorMsg={errors.EndDateRPPEValidator}
          valid={errors.EndDateRPPEValidator || !person.rPPE_DATE_FIN ? false : true}
          onChange={(e) => onChangeExposedPerson('rPPE_DATE_FIN', (e.target as unknown as HTMLInputElement).value)}
          label="Date de fin"
          className="row-7 row-sm-6"
          value={person.rPPE_DATE_FIN ? new Date(person.rPPE_DATE_FIN) : null}
          required
        />
      </div>
      <Select
        error={!person.rPPE_PAYS ? true : false}
        valid={!person.rPPE_PAYS ? false : true}
        name="rPPE_PAYS"
        label="Pays concerné"
        required
        value={person.rPPE_PAYS}
        onChange={(e) => onChangeExposedPerson('rPPE_PAYS', e.target.value)}
      >
        <option value="-1"></option>
        {Object.entries(countries).map(([index, obj]) =>
          <option value={obj.code} key={index}>{obj.name}</option>)}
      </Select>
      <Select
        error={!person.rPPE_NATURE_RELATION ? true : false}
        valid={!person.rPPE_NATURE_RELATION ? false : true}
        name="rPPE_NATURE_RELATION"
        label="Nature de la relation"
        required
        value={person.rPPE_NATURE_RELATION}
        onChange={(e) => onChangeExposedPerson('rPPE_NATURE_RELATION', e.target.value)}
      >
        <option value=""></option>
        {Object.entries(natureRelation).map(([value, label]) => (
          <option value={value} key={value}>{label}</option>
        ))}
      </Select>
    </>
  )
}

ImportantRoleLast12MonthsDetails.propTypes = {
  details: PropTypes.shape({
    role: PropTypes.string,
    country: PropTypes.string,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    organization: PropTypes.string
  }),
  onChange: PropTypes.func,
  optionsList: PropTypes.array.isRequired,
  selectedOption: PropTypes.object,
  setSelectedOption: PropTypes.func.isRequired
}

ExposedPerson.propTypes = {
  person: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    birthDate: PropTypes.any,
    role: PropTypes.string,
    country: PropTypes.string,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    organization: PropTypes.string,
    natureOfRelationship: PropTypes.string
  }),
  onChange: PropTypes.func,
  optionsList: PropTypes.array.isRequired,
  selectedOption: PropTypes.object,
  setSelectedOption: PropTypes.func.isRequired
}

export default Step8;