export const countries = [
    {
        code: "FR",
        name: "France",
        nationality: "Française"
    },
    {
        code: "AF",
        name: "Afghanistan",
        nationality: "Afghane"
    },
    {
        code: "ZA",
        name: "Afrique du Sud",
        nationality: "Sud-africaine"
    },
    {
        code: "AL",
        name: "Albanie",
        nationality: "Albanaise"
    },
    {
        code: "DZ",
        name: "Algérie",
        nationality: "Algerienne"
    },
    {
        code: "DE",
        name: "Allemagne",
        nationality: "Allemande"
    },
    {
        code: "AD",
        name: "Andorre",
        nationality: "Andorrane"
    },
    {
        code: "AO",
        name: "Angola",
        nationality: "Angolaise"
    },
    {
        code: "AI",
        name: "Anguilla",
        nationality: "Anguilla"
    },
    {
        code: "AQ",
        name: "Antarctique",
        nationality: "Antarctique"
    },
    {
        code: "AG",
        name: "Antigua-et-Barbuda",
        nationality: "Antiguaise et barbudienne"
    },
    {
        code: "AN",
        name: "Antilles néerlandaises",
        nationality: "Antilles néerlandaises"
    },
    {
        code: "SA",
        name: "Arabie saoudite",
        nationality: "Saoudienne"
    },
    {
        code: "AR",
        name: "Argentine",
        nationality: "Argentine"
    },
    {
        code: "AM",
        name: "Arménie",
        nationality: "Armenienne"
    },
    {
        code: "AW",
        name: "Aruba",
        nationality: "Aruba"
    },
    {
        code: "AU",
        name: "Australie",
        nationality: "Australienne"
    },
    {
        code: "AT",
        name: "Autriche",
        nationality: "Autrichienne"
    },
    {
        code: "AZ",
        name: "Azerbaïdjan",
        nationality: "Azerbaïdjanaise"
    },
    {
        code: "BS",
        name: "Bahamas",
        nationality: "Bahamienne"
    },
    {
        code: "BH",
        name: "Bahreïn",
        nationality: "Bahreinienne"
    },
    {
        code: "BD",
        name: "Bangladesh",
        nationality: "Bangladaise"
    },
    {
        code: "BB",
        name: "Barbade",
        nationality: "Barbadienne"
    },
    {
        code: "BY",
        name: "Bélarus",
        nationality: "Bielorusse"
    },
    {
        code: "BE",
        name: "Belgique",
        nationality: "Belge"
    },
    {
        code: "BZ",
        name: "Belize",
        nationality: "Belizienne"
    },
    {
        code: "BJ",
        name: "Bénin",
        nationality: "Beninoise"
    },
    {
        code: "BM",
        name: "Bermudes",
        nationality: "Bermudes"
    },
    {
        code: "BT",
        name: "Bhoutan",
        nationality: "Bhoutanaise"
    },
    {
        code: "BO",
        name: "Bolivie",
        nationality: "Bolivienne"
    },
    {
        code: "BA",
        name: "Bosnie-Herzégovine",
        nationality: "Bosnienne"
    },
    {
        code: "BW",
        name: "Botswana",
        nationality: "Botswanaise"
    },
    {
        code: "BR",
        name: "Brésil",
        nationality: "Bresilienne"
    },
    {
        code: "BN",
        name: "Brunéi Darussalam",
        nationality: "Bruneienne"
    },
    {
        code: "BG",
        name: "Bulgarie",
        nationality: "Bulgare"
    },
    {
        code: "BF",
        name: "Burkina Faso",
        nationality: "Burkinabe"
    },
    {
        code: "BI",
        name: "Burundi",
        nationality: "Burundaise"
    },
    {
        code: "KH",
        name: "Cambodge",
        nationality: "Cambodgienne"
    },
    {
        code: "CM",
        name: "Cameroun",
        nationality: "Camerounaise"
    },
    {
        code: "CA",
        name: "Canada",
        nationality: "Canadienne"
    },
    {
        code: "CV",
        name: "Cap-Vert",
        nationality: "Cap-verdienne"
    },
    {
        code: "EA",
        name: "Ceuta et Melilla",
        nationality: "Ceuta et Melilla"
    },
    {
        code: "CL",
        name: "Chili",
        nationality: "Chilienne"
    },
    {
        code: "CN",
        name: "Chine",
        nationality: "Chinoise"
    },
    {
        code: "CY",
        name: "Chypre",
        nationality: "Chypriote"
    },
    {
        code: "CO",
        name: "Colombie",
        nationality: "Colombienne"
    },
    {
        code: "KM",
        name: "Comores",
        nationality: "Comorienne"
    },
    {
        code: "CG",
        name: "Congo-Brazzaville",
        nationality: "Congolaise"
    },
    {
        code: "KP",
        name: "Corée du Nord",
        nationality: "Nord-coréenne"
    },
    {
        code: "KR",
        name: "Corée du Sud",
        nationality: "Sud-coréenne"
    },
    {
        code: "CR",
        name: "Costa Rica",
        nationality: "Costaricaine"
    },
    {
        code: "CI",
        name: "Côte d’Ivoire",
        nationality: "Ivoirienne"
    },
    {
        code: "HR",
        name: "Croatie",
        nationality: "Croate"
    },
    {
        code: "CU",
        name: "Cuba",
        nationality: "Cubaine"
    },
    {
        code: "DK",
        name: "Danemark",
        nationality: "Danoise"
    },
    {
        code: "DG",
        name: "Diego Garcia",
        nationality: "Diego Garcia"
    },
    {
        code: "DJ",
        name: "Djibouti",
        nationality: "Djiboutienne"
    },
    {
        code: "DM",
        name: "Dominique",
        nationality: "Dominiquaise"
    },
    {
        code: "EG",
        name: "Égypte",
        nationality: "Egyptienne"
    },
    {
        code: "SV",
        name: "El Salvador",
        nationality: "Salvadorienne"
    },
    {
        code: "AE",
        name: "Émirats arabes unis",
        nationality: "Emirienne"
    },
    {
        code: "EC",
        name: "Équateur",
        nationality: "Equatorienne"
    },
    {
        code: "ER",
        name: "Érythrée",
        nationality: "Erythreenne"
    },
    {
        code: "ES",
        name: "Espagne",
        nationality: "Espagnole"
    },
    {
        code: "EE",
        name: "Estonie",
        nationality: "Estonienne"
    },
    {
        code: "VA",
        name: "État de la Cité du Vatican",
        nationality: "Vaticane"
    },
    {
        code: "FM",
        name: "États fédérés de Micronésie",
        nationality: "Micronesienne"
    },
    {
        code: "US",
        name: "États-Unis",
        nationality: "Americaine"
    },
    {
        code: "ET",
        name: "Éthiopie",
        nationality: "Ethiopienne"
    },
    {
        code: "FJ",
        name: "Fidji",
        nationality: "Fidjienne"
    },
    {
        code: "FI",
        name: "Finlande",
        nationality: "Finlandaise"
    },
    {
        code: "GA",
        name: "Gabon",
        nationality: "Gabonaise"
    },
    {
        code: "GM",
        name: "Gambie",
        nationality: "Gambienne"
    },
    {
        code: "GE",
        name: "Géorgie",
        nationality: "Georgienne"
    },
    {
        code: "GS",
        name: "Géorgie du Sud et les îles Sandwich du Sud",
        nationality: "Géorgie du Sud et les îles Sandwich du Sud"
    },
    {
        code: "GH",
        name: "Ghana",
        nationality: "Ghaneenne"
    },
    {
        code: "GI",
        name: "Gibraltar",
        nationality: "Gibraltar"
    },
    {
        code: "GR",
        name: "Grèce",
        nationality: "Hellenique"
    },
    {
        code: "GD",
        name: "Grenade",
        nationality: "Grenadienne"
    },
    {
        code: "GL",
        name: "Groenland",
        nationality: "Groenland"
    },
    {
        code: "GP",
        name: "Guadeloupe",
        nationality: "Guadeloupe"
    },
    {
        code: "GU",
        name: "Guam",
        nationality: "Guam"
    },
    {
        code: "GT",
        name: "Guatemala",
        nationality: "Guatemalteque"
    },
    {
        code: "GG",
        name: "Guernesey",
        nationality: "Guernesey"
    },
    {
        code: "GN",
        name: "Guinée",
        nationality: "Guineenne"
    },
    {
        code: "GQ",
        name: "Guinée équatoriale",
        nationality: "Equato-guineenne"
    },
    {
        code: "GW",
        name: "Guinée-Bissau",
        nationality: "Bissau-Guinéenne"
    },
    {
        code: "GY",
        name: "Guyana",
        nationality: "Guyanienne"
    },
    {
        code: "GF",
        name: "Guyane française",
        nationality: "Guyane française"
    },
    {
        code: "HT",
        name: "Haïti",
        nationality: "Haïtienne"
    },
    {
        code: "HN",
        name: "Honduras",
        nationality: "Hondurienne"
    },
    {
        code: "HU",
        name: "Hongrie",
        nationality: "Hongroise"
    },
    {
        code: "BV",
        name: "Île Bouvet",
        nationality: "Île Bouvet"
    },
    {
        code: "CX",
        name: "Île Christmas",
        nationality: "Île Christmas"
    },
    {
        code: "CP",
        name: "Île Clipperton",
        nationality: "Île Clipperton"
    },
    {
        code: "AC",
        name: "Île de l'Ascension",
        nationality: "Île de l'Ascension"
    },
    {
        code: "IM",
        name: "Île de Man",
        nationality: "Île de Man"
    },
    {
        code: "NF",
        name: "Île Norfolk",
        nationality: "Île Norfolk"
    },
    {
        code: "AX",
        name: "Îles Åland",
        nationality: "Îles Åland"
    },
    {
        code: "KY",
        name: "Îles Caïmans",
        nationality: "Îles Caïmans"
    },
    {
        code: "IC",
        name: "Îles Canaries",
        nationality: "Îles Canaries"
    },
    {
        code: "CC",
        name: "Îles Cocos - Keeling",
        nationality: "Îles Cocos - Keeling"
    },
    {
        code: "CK",
        name: "Îles Cook",
        nationality: "Îles Cook"
    },
    {
        code: "FO",
        name: "Îles Féroé",
        nationality: "Îles Féroé"
    },
    {
        code: "HM",
        name: "Îles Heard et MacDonald",
        nationality: "Îles Heard et MacDonald"
    },
    {
        code: "FK",
        name: "Îles Malouines",
        nationality: "Îles Malouines"
    },
    {
        code: "MP",
        name: "Îles Mariannes du Nord",
        nationality: "Îles Mariannes du Nord"
    },
    {
        code: "MH",
        name: "Îles Marshall",
        nationality: "Marshallaise"
    },
    {
        code: "UM",
        name: "Îles Mineures Éloignées des États-Unis",
        nationality: "Îles Mineures Éloignées des États-Unis"
    },
    {
        code: "SB",
        name: "Îles Salomon",
        nationality: "Salomonaise"
    },
    {
        code: "TC",
        name: "Îles Turks et Caïques",
        nationality: "Îles Turks et Caïques"
    },
    {
        code: "VG",
        name: "Îles Vierges britanniques",
        nationality: "Îles Vierges britanniques"
    },
    {
        code: "VI",
        name: "Îles Vierges des États-Unis",
        nationality: "Îles Vierges des États-Unis"
    },
    {
        code: "IN",
        name: "Inde",
        nationality: "Indienne"
    },
    {
        code: "ID",
        name: "Indonésie",
        nationality: "Indonesienne"
    },
    {
        code: "IQ",
        name: "Irak",
        nationality: "Irakienne"
    },
    {
        code: "IR",
        name: "Iran",
        nationality: "Iran"
    },
    {
        code: "IE",
        name: "Irlande",
        nationality: "Irlandaise"
    },
    {
        code: "IS",
        name: "Islande",
        nationality: "Islandaise"
    },
    {
        code: "IL",
        name: "Israël",
        nationality: "Israélienne"
    },
    {
        code: "IT",
        name: "Italie",
        nationality: "Italienne"
    },
    {
        code: "JM",
        name: "Jamaïque",
        nationality: "Jamaïcaine"
    },
    {
        code: "JP",
        name: "Japon",
        nationality: "Japonaise"
    },
    {
        code: "JE",
        name: "Jersey",
        nationality: "Jersey"
    },
    {
        code: "JO",
        name: "Jordanie",
        nationality: "Jordanienne"
    },
    {
        code: "KZ",
        name: "Kazakhstan",
        nationality: "Kazakhstanaise"
    },
    {
        code: "KE",
        name: "Kenya",
        nationality: "Kenyane"
    },
    {
        code: "KG",
        name: "Kirghizistan",
        nationality: "Kirghize"
    },
    {
        code: "KI",
        name: "Kiribati",
        nationality: "Kiribatienne"
    },
    {
        code: "KW",
        name: "Koweït",
        nationality: "Koweitienne"
    },
    {
        code: "LA",
        name: "Laos",
        nationality: "Laotienne"
    },
    {
        code: "LS",
        name: "Lesotho",
        nationality: "Lesothane"
    },
    {
        code: "LV",
        name: "Lettonie",
        nationality: "Lettone"
    },
    {
        code: "LB",
        name: "Liban",
        nationality: "Libanaise"
    },
    {
        code: "LR",
        name: "Libéria",
        nationality: "Liberienne"
    },
    {
        code: "LY",
        name: "Libye",
        nationality: "Libyenne"
    },
    {
        code: "LI",
        name: "Liechtenstein",
        nationality: "Liechtensteinoise"
    },
    {
        code: "LT",
        name: "Lituanie",
        nationality: "Lituanienne"
    },
    {
        code: "LU",
        name: "Luxembourg",
        nationality: "Luxembourgeoise"
    },
    {
        code: "MK",
        name: "Macédoine",
        nationality: "Macedonienne"
    },
    {
        code: "MG",
        name: "Madagascar",
        nationality: "Malgache"
    },
    {
        code: "MY",
        name: "Malaisie",
        nationality: "Malaisienne"
    },
    {
        code: "MW",
        name: "Malawi",
        nationality: "Malawienne"
    },
    {
        code: "MV",
        name: "Maldives",
        nationality: "Maldivienne"
    },
    {
        code: "ML",
        name: "Mali",
        nationality: "Malienne"
    },
    {
        code: "MT",
        name: "Malte",
        nationality: "Maltaise"
    },
    {
        code: "MA",
        name: "Maroc",
        nationality: "Marocaine"
    },
    {
        code: "MQ",
        name: "Martinique",
        nationality: "Martinique"
    },
    {
        code: "MU",
        name: "Maurice",
        nationality: "Mauricienne"
    },
    {
        code: "MR",
        name: "Mauritanie",
        nationality: "Mauritanienne"
    },
    {
        code: "YT",
        name: "Mayotte",
        nationality: "Mayotte"
    },
    {
        code: "MX",
        name: "Mexique",
        nationality: "Mexicaine"
    },
    {
        code: "MD",
        name: "Moldavie",
        nationality: "Moldave"
    },
    {
        code: "MC",
        name: "Monaco",
        nationality: "Monegasque"
    },
    {
        code: "MN",
        name: "Mongolie",
        nationality: "Mongole"
    },
    {
        code: "ME",
        name: "Monténégro",
        nationality: "Montenegrine"
    },
    {
        code: "MS",
        name: "Montserrat",
        nationality: "Montserrat"
    },
    {
        code: "MZ",
        name: "Mozambique",
        nationality: "Mozambicaine"
    },
    {
        code: "MM",
        name: "Myanmar",
        nationality: "Birmane"
    },
    {
        code: "NA",
        name: "Namibie",
        nationality: "Namibienne"
    },
    {
        code: "NR",
        name: "Nauru",
        nationality: "Nauruane"
    },
    {
        code: "NP",
        name: "Népal",
        nationality: "Nepalaise"
    },
    {
        code: "NI",
        name: "Nicaragua",
        nationality: "Nicaraguayenne"
    },
    {
        code: "NE",
        name: "Niger",
        nationality: "Nigerienne"
    },
    {
        code: "NG",
        name: "Nigéria",
        nationality: "Nigeriane"
    },
    {
        code: "NU",
        name: "Niue",
        nationality: "Niue"
    },
    {
        code: "NO",
        name: "Norvège",
        nationality: "Norvegienne"
    },
    {
        code: "NC",
        name: "Nouvelle-Calédonie",
        nationality: "Nouvelle-Calédonie"
    },
    {
        code: "NZ",
        name: "Nouvelle-Zélande",
        nationality: "Neo-zelandaise"
    },
    {
        code: "OM",
        name: "Oman",
        nationality: "Omanaise"
    },
    {
        code: "UG",
        name: "Ouganda",
        nationality: "Ougandaise"
    },
    {
        code: "UZ",
        name: "Ouzbékistan",
        nationality: "Ouzbeke"
    },
    {
        code: "PK",
        name: "Pakistan",
        nationality: "Pakistanaise"
    },
    {
        code: "PW",
        name: "Palaos",
        nationality: "Palau"
    },
    {
        code: "PA",
        name: "Panama",
        nationality: "Panameenne"
    },
    {
        code: "PG",
        name: "Papouasie-Nouvelle-Guinée",
        nationality: "Papouane-neoguineenne"
    },
    {
        code: "PY",
        name: "Paraguay",
        nationality: "Paraguayenne"
    },
    {
        code: "NL",
        name: "Pays-Bas",
        nationality: "Neerlandaise"
    },
    {
        code: "PE",
        name: "Pérou",
        nationality: "Peruvienne"
    },
    {
        code: "PH",
        name: "Philippines",
        nationality: "Philippine"
    },
    {
        code: "PN",
        name: "Pitcairn",
        nationality: "Pitcairn"
    },
    {
        code: "PL",
        name: "Pologne",
        nationality: "Polonaise"
    },
    {
        code: "PF",
        name: "Polynésie française",
        nationality: "Polynésie française"
    },
    {
        code: "PR",
        name: "Porto Rico",
        nationality: "Portoricaine"
    },
    {
        code: "PT",
        name: "Portugal",
        nationality: "Portugaise"
    },
    {
        code: "QA",
        name: "Qatar",
        nationality: "Qatarienne"
    },
    {
        code: "HK",
        name: "R.A.S. chinoise de Hong Kong",
        nationality: "R.A.S. chinoise de Hong Kong"
    },
    {
        code: "MO",
        name: "R.A.S. chinoise de Macao",
        nationality: "R.A.S. chinoise de Macao"
    },
    {
        code: "QO",
        name: "régions éloignées de l’Océanie",
        nationality: "régions éloignées de l’Océanie"
    },
    {
        code: "CF",
        name: "République centrafricaine",
        nationality: "Centrafricaine"
    },
    {
        code: "CD",
        name: "République démocratique du Congo",
        nationality: "République démocratique du Congo"
    },
    {
        code: "DO",
        name: "République dominicaine",
        nationality: "Dominicaine"
    },
    {
        code: "CZ",
        name: "République tchèque",
        nationality: "Tcheque"
    },
    {
        code: "RE",
        name: "Réunion",
        nationality: "Réunion"
    },
    {
        code: "RO",
        name: "Roumanie",
        nationality: "Roumaine"
    },
    {
        code: "GB",
        name: "Royaume-Uni",
        nationality: "Royaume-Uni"
    },
    {
        code: "RU",
        name: "Russie",
        nationality: "Russe"
    },
    {
        code: "RW",
        name: "Rwanda",
        nationality: "Rwandaise"
    },
    {
        code: "EH",
        name: "Sahara occidental",
        nationality: "Sahara occidental"
    },
    {
        code: "BL",
        name: "Saint-Barthélémy",
        nationality: "Saint-Barthélémy"
    },
    {
        code: "KN",
        name: "Saint-Kitts-et-Nevis",
        nationality: "Kittitienne-et-nevicienne"
    },
    {
        code: "SM",
        name: "Saint-Marin",
        nationality: "Saint-Marinaise"
    },
    {
        code: "MF",
        name: "Saint-Martin",
        nationality: "Saint-Martin"
    },
    {
        code: "PM",
        name: "Saint-Pierre-et-Miquelon",
        nationality: "Saint-Pierre-et-Miquelon"
    },
    {
        code: "VC",
        name: "Saint-Vincent-et-les Grenadines",
        nationality: "Saint-Vincentaise-et-Grenadine"
    },
    {
        code: "SH",
        name: "Sainte-Hélène",
        nationality: "Sainte-Hélène"
    },
    {
        code: "LC",
        name: "Sainte-Lucie",
        nationality: "Saint-Lucienne"
    },
    {
        code: "WS",
        name: "Samoa",
        nationality: "Samoane"
    },
    {
        code: "AS",
        name: "Samoa américaines",
        nationality: "Samoa américaines"
    },
    {
        code: "ST",
        name: "Sao Tomé-et-Principe",
        nationality: "Santomeenne"
    },
    {
        code: "SN",
        name: "Sénégal",
        nationality: "Senegalaise"
    },
    {
        code: "RS",
        name: "Serbie",
        nationality: "Serbe"
    },
    {
        code: "CS",
        name: "Serbie-et-Monténégro",
        nationality: "Serbie-et-Monténégro"
    },
    {
        code: "SC",
        name: "Seychelles",
        nationality: "Seychelloise"
    },
    {
        code: "SL",
        name: "Sierra Leone",
        nationality: "Sierra-leonaise"
    },
    {
        code: "SG",
        name: "Singapour",
        nationality: "Singapourienne"
    },
    {
        code: "SK",
        name: "Slovaquie",
        nationality: "Slovaque"
    },
    {
        code: "SI",
        name: "Slovénie",
        nationality: "Slovene"
    },
    {
        code: "SO",
        name: "Somalie",
        nationality: "Somalienne"
    },
    {
        code: "SD",
        name: "Soudan",
        nationality: "Soudanaise"
    },
    {
        code: "LK",
        name: "Sri Lanka",
        nationality: "Sri-lankaise"
    },
    {
        code: "SE",
        name: "Suède",
        nationality: "Suedoise"
    },
    {
        code: "CH",
        name: "Suisse",
        nationality: "Suisse"
    },
    {
        code: "SR",
        name: "Suriname",
        nationality: "Surinamaise"
    },
    {
        code: "SJ",
        name: "Svalbard et Île Jan Mayen",
        nationality: "Svalbard et Île Jan Mayen"
    },
    {
        code: "SZ",
        name: "Swaziland",
        nationality: "Swazie"
    },
    {
        code: "SY",
        name: "Syrie",
        nationality: "Syrienne"
    },
    {
        code: "TJ",
        name: "Tadjikistan",
        nationality: "Tadjike"
    },
    {
        code: "TW",
        name: "Taïwan",
        nationality: "Taiwanaise"
    },
    {
        code: "TZ",
        name: "Tanzanie",
        nationality: "Tanzanienne"
    },
    {
        code: "TD",
        name: "Tchad",
        nationality: "Tchadienne"
    },
    {
        code: "TF",
        name: "Terres australes françaises",
        nationality: "Terres australes françaises"
    },
    {
        code: "IO",
        name: "Territoire britannique de l'océan Indien",
        nationality: "Territoire britannique de l'océan Indien"
    },
    {
        code: "PS",
        name: "Territoire palestinien",
        nationality: "Palestinienne"
    },
    {
        code: "TH",
        name: "Thaïlande",
        nationality: "Thaïlandaise"
    },
    {
        code: "TL",
        name: "Timor oriental",
        nationality: "Est-timoraise"
    },
    {
        code: "TG",
        name: "Togo",
        nationality: "Togolaise"
    },
    {
        code: "TK",
        name: "Tokelau",
        nationality: "Tokelau"
    },
    {
        code: "TO",
        name: "Tonga",
        nationality: "Tonguienne"
    },
    {
        code: "TT",
        name: "Trinité-et-Tobago",
        nationality: "Trinidadienne"
    },
    {
        code: "TA",
        name: "Tristan da Cunha",
        nationality: "Tristan da Cunha"
    },
    {
        code: "TN",
        name: "Tunisie",
        nationality: "Tunisienne"
    },
    {
        code: "TM",
        name: "Turkménistan",
        nationality: "Turkmene"
    },
    {
        code: "TR",
        name: "Turquie",
        nationality: "Turque"
    },
    {
        code: "TV",
        name: "Tuvalu",
        nationality: "Tuvaluane"
    },
    {
        code: "UA",
        name: "Ukraine",
        nationality: "Ukrainienne"
    },
    {
        code: "UY",
        name: "Uruguay",
        nationality: "Uruguayenne"
    },
    {
        code: "VU",
        name: "Vanuatu",
        nationality: "Vanuatuane"
    },
    {
        code: "VE",
        name: "Venezuela",
        nationality: "Venezuelienne"
    },
    {
        code: "VN",
        name: "Viêt Nam",
        nationality: "Vietnamienne"
    },
    {
        code: "WF",
        name: "Wallis-et-Futuna",
        nationality: "Wallis-et-Futuna"
    },
    {
        code: "YE",
        name: "Yémen",
        nationality: "Yemenite"
    },
    {
        code: "ZM",
        name: "Zambie",
        nationality: "Zambienne"
    },
    {
        code: "ZW",
        name: "Zimbabwe",
        nationality: "Zimbabweenne"
    }
]
const codeExist=(code:string)=>{
    return code && countries.findIndex(c=>c.code==code)>=0;
}
export const getNationality = (code: string) => {
    if (!codeExist(code)) return ''
    return countries.find(c => c.code == code)?.nationality;
}
export const getCountry = (code:string) => {
    if (!codeExist(code)) return ''
    return countries.find(c => c.code == code)?.name;
}
