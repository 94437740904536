import { useEffect, useState } from 'react'
import Button from '../components/Button';
import Checkbox from '../components/Checkbox'
import React from 'react';
import axiosInstance from '../Interceptors/axiosconfig';
import { useClient } from '../contexts/LivretMainContext';
import Loader from '../components/Loader';
import Popup from '../components/Popup';
import { useNavigate } from 'react-router-dom';
import GenerateSignatureDocumentRequest from '../types/GenerateSignatureDocumentRequest';
import ErrorComponent from '../components/ErrorComponent';

function Step14() {
  const navigate = useNavigate();
  const { client, setSubStep } = useClient();

  const [ficheInfoChecked, setFicheInfoChecked] = React.useState(false);
  const [CGChecked, setCGChecked] = React.useState(false);
  const [CGVADChecked, setCGVADChecked] = React.useState(false);
  const [error, setError] = useState<boolean>(false);

  const [executionImmediateChecked, setExecutionImmediateChecked] = React.useState(false);
  const [signaturePapierChecked, setSignaturePapierChecked] = React.useState(false);
  const [generateDocumentADGResponse, setGenerateDocumentADGResponse] = useState<boolean | undefined>(undefined);

  const [ficheInfoVisited, setFicheInfoVisited] = React.useState(false);
  const [CGVisited, setCGVisited] = React.useState(false);
  const [CGVADVisited, setCGVADVisited] = React.useState(false);
  const [SignaturePapierPopinVisible, setSignaturePapierPopinVisible] = React.useState(false);


  const handleFinalizeBtnClick = () => {
    signaturePapierChecked ? setSignaturePapierPopinVisible(true)
      : navigate('/electronicsignaturestep');
  }

  const redirectionToPaperSignature = () => {
    setSignaturePapierPopinVisible(false);
    navigate('/PaperSignatureStep');
  }
  useEffect(() => {
    setSubStep(14)
  }, [setSubStep]);

  useEffect(() => {
    const generateDocument = async () => {
      try {
        var generateSignatureDocumentRequest = new GenerateSignatureDocumentRequest({ clientGuid: client.lC_GUID, signatureType: "ELECTRONIQUE" });
        const response = await axiosInstance.post(`/EditiqueMnh/GenerateDocumentADG`, generateSignatureDocumentRequest);
        setGenerateDocumentADGResponse(response.data);
        setError(false);
      }
      catch (err) {
        setError(true);
      }
    }

    if (client.lC_GUID !== "") {
      generateDocument();
    }

  }, []);

  return generateDocumentADGResponse ? (
    <>
      <div className="final-container">
        <h2>Avant de signer votre souscription..</h2>
        <div className="content">
          <div className="optin-container">
            <p>Avant de démarrer l'étape de signature électronique, veuillez prendre connaissance des documents consultables en cliquant sur les liens ci-dessous :</p>
            <Checkbox
              name="fiche_info"
              onChange={() => { setFicheInfoChecked(!ficheInfoChecked); }}
              text={<span>J'ai lu et j'accepte&nbsp;
                <a href={require('../assets/staticFiles/FIP.pdf')} onClick={_ => setFicheInfoVisited(true)}
                  download="fiche d'informatin pré-contractuelle.pdf"
                  target="_blank"
                  rel="noreferrer">la Fiche d'information pré-contractuelle</a></span>}
              checked={ficheInfoChecked}
              disabled={!ficheInfoVisited}
            ></Checkbox>
            <Checkbox
              name="fiche_info"

              onChange={() => { setCGChecked(!CGChecked); }}
              text={<span>J'ai lu et j'accepte&nbsp;
                <a href={require('../assets/staticFiles/CG.pdf')} onClick={_ => setCGVisited(true)}
                  download="mentions relatives aux Conditions Générales.pdf"
                  target="_blank"
                  rel="noreferrer">les mentions relatives aux Conditions Générales</a></span>}
              checked={CGChecked}
              disabled={!CGVisited}
            ></Checkbox>
            <Checkbox
              name="fiche_info"
              onChange={() => { setCGVADChecked(!CGVADChecked); }}
              text={<span>J'ai lu et j'accepte&nbsp;
                <a href={require('../assets/staticFiles/CGVAD.pdf')} onClick={_ => setCGVADVisited(true)}
                  download="conditions générales de vente à distance.pdf"
                  target="_blank"
                  rel="noreferrer">les Conditions Générales de Vente à Distance</a></span>}
              checked={CGVADChecked}
              disabled={!CGVADVisited}
            ></Checkbox>
          </div>

          <p>Nous vous rappelons que vous bénéficez d'un délai de rétractation. Celui-ci est d'une durée de 14 jours calendaires à compter de la date de signature de votre Contrat.<br />
          A cette fin, vous pouvez nous adresser votre demande à l'aide du bordereau de rétractation joint à la liasse contractuelle ou sur papier libre par courrier à l'adresse suivante : </p>
          <div className="adress">
            <p>Banque Française Mutualiste</p>
            <p>Service Epargne</p>
            <p>56-60 rue de la Glacière</p>
            <p>75 013 Paris</p>
          </div>
          <p>ou via l'espace client que vous aurez activé après signature.</p>
          <div className='optin-container2'>
            <Checkbox
              name="fiche_info"
              onChange={() => { setExecutionImmediateChecked(!executionImmediateChecked); }}
              text="Je demande l'exécution immédiate du Contrat. J'ai bien noté que le commencement d'exécution n'entraine pas la renonciation au délai de rétractation de 14 jours à compter de la date de la signature du Contrat"
              checked={executionImmediateChecked}
            ></Checkbox>
            <p className='space'>Si vous ne souhaitez pas signer par voie électronique votre demande d'ouverture de livret, vous pouvez nous adresser votre demande par courrier à l'adresse ci-dessus. Dans ce cas, les délais de traitement seront plus long avec ce procédé. </p>
            <Checkbox
              name="fiche_info"
              onChange={() => { setSignaturePapierChecked(!signaturePapierChecked); }}
              text="En cochant cette case, je confirme ma volonté de ne pas signer par voie électronique. Je vous adresserai par voie postale la demande d'ouverture de livret complétée et signée"
              checked={signaturePapierChecked}
            ></Checkbox>
          </div>
        </div>
        <div className="button-container">
          <Button type="next" disabled={!ficheInfoChecked || !CGChecked || !CGVADChecked} onClick={handleFinalizeBtnClick}>Finaliser la souscription</Button>
        </div>
      </div>
      <Popup
        title="Signature papier"
        content="Vous avez choisi l'envoi par courrier. Il sera nécessaire d'imprimer, parapher, signer puis nous renvoyer ce document par voie postale. Merci de nous confirmer votre choix."
        visible={SignaturePapierPopinVisible} useOverlay={true}
        width='480px' height='320px' okLabel='Confirmer' closeLabel='Annuler'
        okButtontype='primary-center' closeButtontype='secondary-center'
        onClose={() => setSignaturePapierPopinVisible(false)}
        onOk={() => redirectionToPaperSignature()}
      />
    </>
  ) : generateDocumentADGResponse === false || error || client.lC_GUID == "00000000-0000-0000-0000-000000000000" ?
    <ErrorComponent
      errorMessage={'Veuillez réessayer ultérieurement, le service est momentanément indisponible'}
      displayButton={true}
      buttonText={'Retour à la page précédente'}
      step={'/step13'}/>
    : <Loader></Loader>
}

export default Step14