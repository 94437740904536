import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { Client } from "../types/client";
import { FormsErros } from "../types/FormsErros";

const errors_list: FormsErros = {
    EmailValidator: undefined,
    ConfirmationEmailValidator: undefined,
    PhoneNumberValidator: undefined,
    FirstNameValidator: undefined,
    LastNameValidator: undefined,
    CivilityValidator: undefined,
    BirthDateValidator: undefined,
    SGPartnerValidator: undefined,
    Address1Validator: undefined,
    Address2Validator: undefined,
    ZipCodeValidator: undefined,
    CityValidator: undefined,
    HostedByThirdPartyValidator: undefined,
    TaxResidenceFranceValidator: undefined,
    TaxObligationsOtherCountryValidator: undefined,
    TaxObligationsOtherCountryIdentifier0Validator: undefined,
    TaxObligationsOtherCountryName0Validator: undefined,
    TaxMotifNif0Validator: undefined,
    TaxObligationsOtherCountryIdentifier1Validator: undefined,
    TaxObligationsOtherCountryName1Validator: undefined,
    TaxMotifNif1Validator: undefined,
    TaxObligationsOtherCountryIdentifier2Validator: undefined,
    TaxObligationsOtherCountryName2Validator: undefined,
    TaxMotifNif2Validator: undefined,
    UsCitizenValidator: undefined,
    UsCitizenFiscalIdValidator: undefined,
    UsCitizenMotifNifValidator: undefined
}

const new_client: Client = {
    lC_GUID: "00000000-0000-0000-0000-000000000000",
    lC_STEP: 1,
    lC_NOM: "",
    lC_PRENOM: "",
    lC_AUTRE_NOM: "",
    lC_CP_NAISSANCE: "",
    lC_VILLE_NAISSANCE: "",
    lC_PAYS_NAISSANCE: "",
    lC_DATE_NAISSANCE: null,
    lC_CLIENT_MAJEUR_CAPABLE: null,
    lC_DETENTEUR_LIVRET_BFM: null,
    lC_EMAIL: "",
    lC_FILE: "",
    lC_CONFIRMATION_EMAIL: "",
    lC_TELEPHONE: "",
    lC_SITUATION_FAM: 99,
    lC_REVENUE_MENSUEL: 99,
    lC_PATRIMOINE_FINANCIER: 99,
    lC_PATRIMOINE_IMMOBILIER: 99,
    lC_PPREMIER_VERSEMENT: null,
    lC_CIVILITE: null,
    lC_STATUT: 0,
    lC_NATIONALITE: "",
    lC_CLIENT_SG: false,
    lC_COORD_ADR1: "",
    lC_COORD_ADR2: "",
    lC_COORD_CP: "",
    lC_COORD_VILLE: "",
    lC_COORD_PAYS: "FR",
    lC_COMMUNIC_COMM_OK: false,
    lC_HEBERGEMENT_TIERS: null,
    lC_EST_RESD_FISCALE_FRANCE: null,
    lC_EST_RESD_FISCALE_AUTRES: null,
    lIVRET_AUTRE_RESID_FISCALES: [],
    lC_EST_CITOYEN_USA: null,
    lC_EST_PPE: null,
    lC_EST_RELATION_PPE: null,
    lC_ROLE_IMPORTANT_DERNIERS_MOIS: null,
    lC_ID_FISCALE_USA: "",
    rELATION_PPE: null,
    lIVRET_CLIENT_PPE: null,
    lIVRET_CLIENT_ORIGINES_FONDS: [],
    lC_SITUATION_PRO: -1,
    lC_PROFESSION: -1,
    lC_INTITULE_POSTE: "",
    lC_CSP_CODE: -1,
    lC_CSP_LIBELLE: "",
    lC_JUMP_EASYCONFORM_STEP: false,
    lIVRET_CLIENT_DOCUMENTS: [],
    isindicationmode: false
};

interface ClientContextType {
    mainErrors: FormsErros
    client: Client,
    setClient: Dispatch<SetStateAction<Client>>,
    setMainErrors: Dispatch<SetStateAction<FormsErros>>,
    setSubStep: Dispatch<SetStateAction<number>>,
    mainStep: number,
    stepsLabels: string[],
    subStep: number,
    progress: number,
}

const stepsLabels = [
    'Vos informations personnelles.',
    'Votre situation et vos finances',
    'Votre épargne souhaitée',
    'Vos pièces justificatives'
]

const totalSubStep = 12;

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const ClientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [client, setClient] = useState<Client>(new_client);
    const [mainErrors, setMainErrors] = useState<FormsErros>(errors_list);
    const [subStep, setSubStep] = useState(1);
    const [mainStep, setMainStep] = useState(3);
    const [progress, setProgress] = useState(0);

    const calcMainSteps = (currentStep: number) => {
        if (currentStep > 11) {
            setMainStep(4)
        } else if (currentStep === 11) {
            setMainStep(3)
        } else if (currentStep > 5 && currentStep <= 10) {
            setMainStep(2)
        } else {
            setMainStep(1)
        }
    }

    const calcProgress = (currentStep: number) => {
        setProgress(Math.floor(((currentStep - 1) / totalSubStep) * 100))
    }

    useEffect(() => {
        calcMainSteps(subStep);
        calcProgress(subStep);
        setClient({ ...client, lC_STEP: subStep });
    }, [subStep]);

    return (
        <ClientContext.Provider value={{
            client,
            mainErrors,
            setMainErrors,
            setClient,
            mainStep,
            stepsLabels,
            subStep,
            setSubStep,
            progress
        }}>
            {children}
        </ClientContext.Provider >
    );
}

export const useClient = (): ClientContextType => {
    const context = useContext(ClientContext);
    if (!context) {
        throw new Error("Useclient must be used within provider")
    }
    return context;
}