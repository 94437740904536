import React from 'react'
import Calendar from "../components/Calendar";
import Input from "../components/Input";
import Radio from "../components/Radio";
import BottomNavigation from "../components/BottomNavigation";
import { civility } from '../utils/labels'
import { useEffect, useState } from "react";
import { useClient } from "../contexts/LivretMainContext";
import { ReactComponent as LogoSG } from '../assets/icons/logo-SG.svg'
import { validate, validateMandatory } from '../services/ValidateStep1Service';
import Popup from '../components/Popup';
import infoPrimary from '../assets/icons/info-primary.svg'
import Button from '../components/Button';
import axiosInstance from '../Interceptors/axiosconfig';
import { Client } from '../types/client';
import ReadContextDtoRequest from '../types/ReadContextDto';
import axios, { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";


const Step1: React.FC = () => {
  const url = window.location.href;
  const { client, setClient, mainErrors, setMainErrors, setSubStep } = useClient();
  const [canProceed, setCanProceed] = useState(false);
  const [redirectionPopupVisible, setIsVisiblePopup] = useState<boolean>(false);
  const [redirectionPopupTitle, setPopupTitle] = useState<string>("");
  const [popupBody, setPopupBody] = useState<string>("");
  const [redirectionUrl, setRedirectionUrl] = useState<string>("https://www.bfm.fr");
  const [error, setError] = useState<boolean>(false);
  const [isVisibleLinkPopup, setisVisibleLinkPopup] = useState<boolean>(false);
  const navigate = useNavigate();


  useEffect(() => {
      readContext();
  }, []);

  //validation coté back et affichage des messages d'eurreurs
  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var isValid = Object.values(mainErrors).every(x => x === "");
    setCanProceed(!(isValid && validMandatory))
  }, [mainErrors]);

  // validation coté front on client change
  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var validateModel = validate(client);
    setCanProceed(validateModel.isValid && !validMandatory);
    setMainErrors(validateModel.errors);
  }, [client])

  useEffect(() => {
    setSubStep(1)
  }, [setSubStep]);

  useEffect(() => {
    if(error)
    navigate("/error");
  }, [error]);


  function handleClientMajeurCapableChange(e: any): any {
    setClient({ ...client, lC_CLIENT_MAJEUR_CAPABLE: e });
    setPopupTitle("Vous n'êtes pas un majeur capable");
    setPopupBody("Compte tenu de votre situation, nous ne pouvons malheureusement pas vous proposer notre produit pour le moment.");
    setIsVisiblePopup(!e)
  }

  function handleDetenteurLivretBFMChange(e: any): any {
    setClient({ ...client, lC_DETENTEUR_LIVRET_BFM: e });
    setPopupTitle("Vous détenez déjà un livret Banque Française Mutualiste");
    setPopupBody("Vous n'êtes malheureusement pas éligible à cette offre. Rendez-vous sur banquefrançaisemutualiste.fr pour découvrir tous nos produits ?");
    setIsVisiblePopup(e);
  }

  // function handleSGClientChange(e: any): any {
  //   setClient({ ...client, lC_CLIENT_SG: e });
  //   setPopupTitle("Vous êtes client de notre partenaire SG");
  //   setPopupBody("Afin de vous proposez une offre adaptée, nous vous conseillons de prendre rendez-vous avec votre conseiller.");
  //   setRedirectionUrl("https://particuliers.sg.fr/vos-rendez-vous")
  //   setIsVisiblePopup(e);
  // }


  const readContext = async () => {
    try {
      const response = await axiosInstance.post<Client>(`/Client/ReadContext`, new ReadContextDtoRequest({url : url}));
      if (response.data.isindicationmode) {
        
        setClient({
          ...client,
          lC_CIVILITE: response.data.lC_CIVILITE,
          lC_NOM: response.data.lC_AUTRE_NOM,
          lC_PRENOM: response.data.lC_PRENOM,
          lC_EMAIL: response.data.lC_EMAIL,
          lC_TELEPHONE: response.data.lC_TELEPHONE,
          lC_CLIENT_SG: response.data.lC_CLIENT_SG,
          isindicationmode: response.data.isindicationmode
        });
      }
      setError(false);
      setisVisibleLinkPopup(false);
    }
    catch (err) {

      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        if (axiosError.response?.status === 409) {
          setisVisibleLinkPopup(true);
        }
        else {
          setError(true);
        }

      }
      else {
        setError(true);
      }
    }
  }


  const closeUniqueLinkPopUp = () => {
    setisVisibleLinkPopup(false);
    window.location.replace("https://www.bfm.fr");
  }

  const RedirectionPopupContent: JSX.Element =
    <div className='flex flex-column align-center gap-30'>
      <strong>{popupBody}</strong>
      <Button type="next"
        onClick={() => {
          window.location.replace(redirectionUrl)
        }}>OK</Button></div>

  return (
      <>
        <div className="main">
          <div className="form">
            <h2>Faisons connaissance&nbsp;!</h2>
            <div className="form-content">
              <Radio
                label="Civilité"
                name="lC_CIVILITE"
                required
                data={civility}
                inline
                value={client.lC_CIVILITE}
                onChange={(e) => setClient({ ...client, lC_CIVILITE: e })}
              />

              <Input
                name="lC_NOM"
                error={mainErrors.LastNameValidator || !client.lC_NOM ? true : false}
                errorMsg={mainErrors.LastNameValidator}
                valid={mainErrors.LastNameValidator || !client.lC_NOM ? false : true}
                onChange={(e) => setClient({ ...client, lC_NOM: e.target.value })}
                label="Nom"
                required
                value={client.lC_NOM}
                disabled={client.isindicationmode}
              />
              {
                client.lC_CIVILITE == "Mme" ?
                  <>
                    <Input
                      name="lC_AUTRE_NOM"
                      error={mainErrors.OtherLastNameValidator || !client.lC_AUTRE_NOM ? true : false}
                      errorMsg={mainErrors.OtherLastNameValidator}
                      valid={mainErrors.OtherLastNameValidator || !client.lC_AUTRE_NOM ? false : true}
                      onChange={(e) => setClient({ ...client, lC_AUTRE_NOM: e.target.value })}
                      label="Nom de naissance"
                      required
                      value={client.lC_AUTRE_NOM}
                    />
                  </> : ''
              }
              <Input
                name="lC_PRENOM"
                error={mainErrors.FirstNameValidator || !client.lC_PRENOM ? true : false}
                errorMsg={mainErrors.FirstNameValidator}
                valid={mainErrors.FirstNameValidator || !client.lC_PRENOM ? false : true}
                onChange={(e) => setClient({ ...client, lC_PRENOM: e.target.value })}
                label="Prénom"
                required
                value={client.lC_PRENOM}
                disabled={client.isindicationmode}
              />
              <Calendar name="lC_DATE_NAISSANCE"
                error={mainErrors.BirthDateValidator || !client.lC_DATE_NAISSANCE ? true : false}
                errorMsg={mainErrors.BirthDateValidator}
                valid={mainErrors.BirthDateValidator || !client.lC_DATE_NAISSANCE ? false : true}
                onChange={(e) => setClient({ ...client, lC_DATE_NAISSANCE: new Date((e.target as HTMLInputElement).value) ?? null })}
                value={client.lC_DATE_NAISSANCE ? new Date(client.lC_DATE_NAISSANCE) : null}
                required
                label="Date de naissance"
              />
              <Radio
                className="align-label"
                label="Êtes-vous un majeur capable ?"
                name="lC_CLIENT_MAJEUR_CAPABLE"
                required
                value={client.lC_CLIENT_MAJEUR_CAPABLE}
                onChange={(e) => handleClientMajeurCapableChange(e)}
              />
            {/* {<Radio
              className="align-label"
              label={
                <>
                  Êtes-vous client de notre partenaire
                  <LogoSG />
                  ?
                </>
              }
              name="lC_CLIENT_SG"
              required
              value={client.lC_CLIENT_SG}
              onChange={(e) => handleSGClientChange(e)}
            />} */}
              {mainErrors.SGPartnerValidator && <span className="text-white radio--error-message">{mainErrors.SGPartnerValidator}</span>}
              <Radio
                className="align-label"
                label="Êtes-vous déjà détenteur d'un livret Banque Française Mutualiste ?"
                name="lC_DETENTEUR_LIVRET_BFM"
                required
                value={client.lC_DETENTEUR_LIVRET_BFM}
                onChange={(e) => handleDetenteurLivretBFMChange(e)}
              />
            </div>
            <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
          </div>
        </div>
        <div className="helper-container mt-5">
          {''}
        </div>
        <BottomNavigation
          prevDisabled
          nextDisabled={canProceed}
          next="/step2"
        />

        <Popup
          title={redirectionPopupTitle}
          content={RedirectionPopupContent}
          visible={redirectionPopupVisible} icon={infoPrimary} useOverlay={false}
          width='480px' height='420px'
          onClose={() => setIsVisiblePopup(false)}
          hasFooter={false}/>

        <Popup
          title={''}
          content={"Le lien utilisé est à usage unique."}
          htmlContent={undefined}
          updateDate={undefined}
          visible={isVisibleLinkPopup}
          width='480px' 
          height='420px'
          onClose={closeUniqueLinkPopUp} />

      </>
    );
};

export default Step1;