import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { isNullOrWhiteSpace } from "../utils/string";

export const validateMandatory =(client:Client): boolean=>{
  return !isNullOrWhiteSpace(client.lC_TELEPHONE) && !isNullOrWhiteSpace(client.lC_EMAIL);
}

export const validate = (client:Client) : StepValidatorModel => {
  let tempsErrors : FormsErros = {};
  
  if(!isNullOrWhiteSpace(client.lC_EMAIL) && !validateEmail(client.lC_EMAIL)){
    tempsErrors.EmailValidator = "Veuillez respecter le format du courriel (exemple@domaine.fr)";
  }

  if(client.lC_EMAIL && client.lC_EMAIL !== client.lC_CONFIRMATION_EMAIL){
    tempsErrors.ConfirmationEmailValidator = "Les 2 adresses mail ne sont pas identiques";
  }
  else if(!client.lC_EMAIL){
    tempsErrors.ConfirmationEmailValidator = " ";
  }

  if(client.lC_TELEPHONE && !validatePhoneNumber(/^\d+$/,client.lC_TELEPHONE)){
    tempsErrors.PhoneNumberValidator = "Votre numéro doit être composé uniquement de chiffres";
  }
  else if(client.lC_TELEPHONE && client.lC_TELEPHONE.length > 10){
    tempsErrors.PhoneNumberValidator = "Votre numéro comporte plus de 10 chiffres"
  } 
  else if(client.lC_TELEPHONE && client.lC_TELEPHONE.length !==10){
    tempsErrors.PhoneNumberValidator = "Votre numéro de téléphone n'est pas valide"
  }
  else if(client.lC_TELEPHONE && !validatePhoneNumber(/^(06|07)\d{8}$/,client.lC_TELEPHONE)){
    tempsErrors.PhoneNumberValidator = "Votre numéro de téléphone portable ne commence ni par 06 ni par 07"
  }
  
    var isValid= Object.values(tempsErrors).every(x => x === "") 
    
    let stepValidatorModel : StepValidatorModel = {
      isValid:!isValid,
      errors :tempsErrors
    };
    return stepValidatorModel;
  }
  
  const validateEmail = (email:string) : boolean => {
    const regx=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regx.test(email);
  }
  
  const validatePhoneNumber = (regx : RegExp,phoneNumber:string) : boolean => {
    return regx.test(phoneNumber);
  }