export const csp = [
{code:1000,libelle:"Agriculteurs"},
{code:2100,libelle:"Artisans"},
{code:2200,libelle:"Commerçants et assimilés"},
{code:2300,libelle:"Chefs d'entreprise de plus de 10 personnes"},
{code:3100,libelle:"Professions libérales"},
{code:3300,libelle:"Cadres de la fonction publique"},
{code:3400,libelle:"Professeurs et professions scientifiques"},
{code:3500,libelle:"Professions de l'information, de l'art et des spectacles"},
{code:3700,libelle:"Cadres administratifs et commerciaux"},
{code:3800,libelle:"Cadres techniques d'entreprise"},
{code:4200,libelle:"Professions de l'enseignement primaire et professionnel"},
{code:4300,libelle:"Intermédiaires de la santé et du travail social"},
{code:4400,libelle:"Religieux"},
{code:4500,libelle:"Intermédiaires de la fonction publique"},
{code:4600,libelle:"Intermédiaires des entreprises"},
{code:4700,libelle:"Techniciens"},
{code:4800,libelle:"Agents de maîtrise de production"},
{code:5200,libelle:"Employés de la fonction publique"},
{code:5300,libelle:"Policiers, militaires et agents de sécurité privée"},
{code:5400,libelle:"Employés administratifs d'entreprise"},
{code:5500,libelle:"Employés de commerce"},
{code:5600,libelle:"Personnels des services aux particuliers"},
{code:6200,libelle:"Ouvriers qualifiés de type industriel"},
{code:6300,libelle:"Ouvriers qualifiés de type artisanal"},
{code:6400,libelle:"Conducteurs du transport"},
{code:6500,libelle:"Conducteurs d'engins et magasiniers"},
{code:6700,libelle:"Ouvriers peu qualifiés de type industriel"},
{code:6800,libelle:"Ouvriers peu qualifiés de type artisanal"},
{code:6900,libelle:"Ouvriers agricoles"},
{code:80,libelle:"Personnes sans activités professionnelles"},
{code:84,libelle:"Elèves/Etudiants"},
{code:70,libelle:"Retraités"}
]