import PropTypes from 'prop-types';

function Select({
  name,
  label,
  value,
  onChange,
  className = '',
  required = false,
  valid = false,
  error = false,
  errorMsg = '',
  children,
  disabled = false
}) {

  return (
    <div className={`form-group${error && error.length > 0 ? ' error-message' : ''} ${className}`}>
      <select
        className={`form_select ${value !== "" && value !== -1 && value !== 99 ? "filled" : ""}`}
        onChange={onChange}
        value={value}
        id={name}
        name={name}
        disabled={disabled}
      >
        {children}
      </select>
      <label htmlFor={name} className="form_label">
        {label}
        {required && <span className="required">*</span>}
      </label>
      {valid && <span className="form_input--valid"><i className="pi pi-chevron-down" style={{color: '#1AE451'}}/></span>}
      {/* {error && <span className="form_input--error"><i className="pi pi-chevron-down" style={{color: '#DC4D57'}}/></span>} */}
      {error && errorMsg.length > 0 && <span className="form_input--error-message" style={{visibility:'visible'}} >{errorMsg}</span>} 
      {error && <span className="arrow"><i className="pi pi-chevron-down" /></span> }
    </div>
  )
}

Select.propTypes = {
  value:  PropTypes.oneOfType([
    PropTypes.string.isRequired,  
    PropTypes.number.isRequired,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.object]),
  disabled: PropTypes.string,
}

export default Select