import { useNavigate } from "react-router-dom";
import { useFetchClients, useFetchClient } from "../hooks/ClientHooks";
import { Client } from "../types/client";
import { useClient } from '../contexts/LivretMainContext';

function CallHooks(client_id: string) {
    const FetchClientMutation = useFetchClient(client_id);
}

const HouseList = () => {
    const client_id = 0;
    const nav = useNavigate();
    //   const { data, isPending } = useFetchClients();
    const { data } = useFetchClients();
    //   if (isPending) return <h2>Hold on</h2>;
    //   console.log(data);


    const { client, setSubStep } = useClient();

    /*const FetchAndGoNext = (lC_ID:number) => {
        
        CallHooks(lC_ID);
        nav("1");
        }
        */
    return (
        <>
            <div className="main">
                <div className="form">
                    <h2>Clients currently in data base !</h2>
                    <div className="form-content">
                        <table className="table-global table-hover" >
                            <thead>
                                <tr>
                                    <th className="table-th">Nom</th>
                                    <th className="table-th">Prénom</th>
                                    <th className="table-th">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.map((c: Client) => (
                                        <tr key={c.lC_GUID} onClick={() => nav(`/StepEdit/${c.lC_GUID}`)}>
                                            <td>{c.lC_NOM}</td>
                                            <td>{c.lC_PRENOM}</td>
                                            <td>{c.lC_EMAIL}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HouseList;