import CheckboxMultiple from '../components/CheckboxMultiple'
import Helper from '../components/Helper'
import Input from '../components/Input'
import BottomNavigation from '../components/BottomNavigation'
import { useEffect, useState } from 'react'
import { sourceOfFunds as sources } from '../utils/labels'
import { useClient } from '../contexts/LivretMainContext'
import { sourceOfFunds } from '../types/sourceOfFunds'
import { validate, validateMandatory } from '../services/ValidateStep11Service'
import Select from '../components/Select'

function Step11() {
  const { setSubStep, client, setClient, mainErrors, setMainErrors } = useClient();
  const [canProceed, setCanProceed] = useState(false);
  const [isOtherFundsSelected, setIsOtherFundsSelected] = useState(client.lIVRET_CLIENT_ORIGINES_FONDS.some(x => x.lcoF_ORIGINES_FONDS === 5));

  const updateOtherSourceOfFunds = (value: any) => {
    const newSourcesFunds = client.lIVRET_CLIENT_ORIGINES_FONDS.map((el) => {
      if (el.lcoF_ORIGINES_FONDS == 5) {
        return { ...el, ['lcoF_AUTRES_FONDS']: value }
      }
      return el
    })
    setClient({ ...client, lIVRET_CLIENT_ORIGINES_FONDS: newSourcesFunds });
  }

  const updateSourceRefunds = (e: any) => {
    let sourcesFunds: sourceOfFunds[] = []
    e.forEach((element: any) => {
      sourcesFunds.push({ lcoF_ORIGINES_FONDS: Number(element), lcoF_AUTRES_FONDS: client.lIVRET_CLIENT_ORIGINES_FONDS.find(x => x.lcoF_ORIGINES_FONDS == element)?.lcoF_AUTRES_FONDS ?? "", lC_GUID: client.lC_GUID })
    });
    setClient({ ...client, lIVRET_CLIENT_ORIGINES_FONDS: sourcesFunds });
    setIsOtherFundsSelected(e.includes('5'));
  }

  //validation coté back et affichage des messages d'erreurs
  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var isValid = Object.values(mainErrors).every(x => x === "");
    setCanProceed(!(isValid && validMandatory))
  }, [mainErrors]);

  // validation coté front on client change
  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var validateModel = validate(client);
    setCanProceed(validateModel.isValid && !validMandatory);
    setMainErrors(validateModel.errors);
  }, [client])

  useEffect(() => {
    setSubStep(11)
  }, [setSubStep]);

  const checkboxSources = Object.entries(sources)
  .filter(([key]) => parseInt(key) <= 5 )
  .reduce((obj: { [key: string]: string }, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Votre Livret BFM Avenir</h2>
          <div className='mb-4'>
            <Helper>
              <p>Le montant minimum de vos versements doit être de 10 €. Après la validation de votre livret, vous pourrez effectuer des versements complémentaires ponctuels et/ou réguliers.</p>
            </Helper> 
          </div>  
          <div className="form-content">
            <Input
              name="lC_PPREMIER_VERSEMENT"
              error={mainErrors.initialPaymentAmountValidator || !client.lC_PPREMIER_VERSEMENT ? true : false}
              errorMsg={mainErrors.initialPaymentAmountValidator}
              valid={mainErrors.initialPaymentAmountValidator || !client.lC_PPREMIER_VERSEMENT ? false : true}
              onChange={(e) => setClient({ ...client, lC_PPREMIER_VERSEMENT: e.target.value })}
              label="Montant du premier versement"
              required
              value={client.lC_PPREMIER_VERSEMENT ? client.lC_PPREMIER_VERSEMENT.toString() : ''}
            />
            <CheckboxMultiple
              label="Quelles sont les origines des fonds du versement envisagé ?"
              name="lIVRET_CLIENT_ORIGINES_FONDS"
              required
              onChange={(val) => updateSourceRefunds(val)}
              value={client.lIVRET_CLIENT_ORIGINES_FONDS.map(x => x.lcoF_ORIGINES_FONDS?.toString())}
              data={checkboxSources}
            />
            <Select
              name="lIVRET_CLIENT_ORIGINES_FONDS"
              label="Autres origines des fonds"
              onChange={(e) => updateOtherSourceOfFunds(e.target.value)}
              error={!client.lIVRET_CLIENT_ORIGINES_FONDS.find(x => x.lcoF_ORIGINES_FONDS == 5)?.lcoF_AUTRES_FONDS && isOtherFundsSelected ? true : false}
              errorMsg={isOtherFundsSelected && !client.lIVRET_CLIENT_ORIGINES_FONDS.find(x => x.lcoF_ORIGINES_FONDS == 5)?.lcoF_AUTRES_FONDS ? 'Veuillez sélectionner une option valide' : ''}
              valid={isOtherFundsSelected && client.lIVRET_CLIENT_ORIGINES_FONDS.find(x => x.lcoF_ORIGINES_FONDS == 5)?.lcoF_AUTRES_FONDS ? true : false}
              required={isOtherFundsSelected}
              disabled={!isOtherFundsSelected}
              value={client.lIVRET_CLIENT_ORIGINES_FONDS.find(x => x.lcoF_ORIGINES_FONDS == 5)?.lcoF_AUTRES_FONDS ?? ''}
            >
              <option value=""> </option>
              {Object.entries(sources).filter(([key]) => parseInt(key) > 5).map(([key, value]) => (
                <option key={key} value={key}>{value}</option>
              ))}
            </Select>
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container"> 
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed}
        prev="/step10"
        next="/step12"
      />
    </>
  )
}

export default Step11;

