import { useState } from "react";

export const useToggle = (intialState = false) => {
  const [toggle, setToggle] = useState(intialState);

  const toggleChange = () => {
    setToggle(!toggle);
  }

  return [toggle, toggleChange]
}