import MainRouter from "./router/MainRouter"
import logo from "./assets/logo.svg";
import Button from "./components/Button";
import HeroHeader from "./components/HeroHeader";
import { useClient } from "./contexts/LivretMainContext";
import Footer from "../src/components/Footer";
import { useGetParamFooter } from "./hooks/ClientHooks";
import ProgressBar from "./components/ProgressBar";
import { useLocation } from "react-router-dom";

function App() {
  const { subStep, progress } = useClient();
  const location = useLocation();
  const { data } = useGetParamFooter();
  const openFAQPage = () => {
    if (location.pathname != '/faq')
      window.open("/faq", "_blank")
  }
  return (
    <>
      <header className="fixed-header">
        <div className="content">
          <img src={logo} alt="" />
          <Button onClick={_ => openFAQPage()} type="contact">Besoin d'aide ?</Button>
        </div>
      </header>
      {subStep === 1 ? <HeroHeader /> : ''}
      <ProgressBar value={progress} className={"desktop"} />
      <div className="content">
        <MainRouter />
      </div>
      <Footer data={data || []} />
    </>
  )
}

export default App
