import { ProgressBarType } from '../interface'
import { useLocation } from 'react-router-dom';
import { useClient } from '../contexts/LivretMainContext';

function ProgressBar({ value = 0, className = '' }: ProgressBarType) {
  const { subStep } = useClient();
  const location = useLocation();

  if (subStep > 12) return <></>
  if (!location.pathname.includes('/step')) return <></>

  return (
    <div className={`progress-bar-container ${className}`}>
      {className == 'mobile' && <span>{value} %</span>}
      <div className="progress-bar">
        <div className="progress" style={{ width: `${value}%` }}></div>
      </div>
      {className == 'desktop' && <span>{value} %</span>}
    </div>
  )
}

export default ProgressBar