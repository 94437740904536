import { useEffect, useState } from "react";
import { useClient } from "../contexts/LivretMainContext";
import pdf from "../assets/icons/pdf.svg"
import axiosInstance from "../Interceptors/axiosconfig";
import Loader from "../components/Loader";
import GenerateSignatureDocumentRequest from "../types/GenerateSignatureDocumentRequest";
import ErrorComponent from "../components/ErrorComponent";


function PaperSignatureStep() {
  const [file, setFile] = useState<string | undefined>();
  const { setSubStep, client } = useClient();
  const [generateDocumentADGResponse, setGenerateDocumentADGResponse] = useState<boolean>();
  const [error, setError] = useState<boolean>(false);

  const handleDownloadOffreLivretOnClick =  async() => {
        const response = await axiosInstance.get(`/PaperSignature/GetLivretOfferToSign?clientGuid=${client.lC_GUID}`);
        setFile(response.data); 
  }

  useEffect(() => {
    setSubStep(50);
  }, [setSubStep]);


  useEffect(() => {
    const generateDocument = async () => {

      try {
        var generateSignatureDocumentRequest = new GenerateSignatureDocumentRequest({ clientGuid: client.lC_GUID, signatureType: "PAPIER" });
        const response = await axiosInstance.post(`/EditiqueMnh/GenerateDocumentADG`, generateSignatureDocumentRequest);
        setGenerateDocumentADGResponse(response.data);
        setError(false);
      }
      catch (err) {
        setError(true);
      }
    }

    if (client.lC_GUID !== "") {
      generateDocument();

    }

  }, []);

  useEffect(() => {
    handleDownloadSignedContract();
  }, [file]);
  function handleDownloadSignedContract() {
    if (file) {
      const mimType = 'application/pdf';
      var blob = base64ToBlob(file, mimType);
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = "BFM_Offre_Livret.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
    setFile(undefined);
  }
  function base64ToBlob(file: string, mimtype: string) {
    const byteCharacters = atob(file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimtype });
  }
  return generateDocumentADGResponse ? (
    <>
      <div className="papaer-signature-step">
        <h2>Vous avez choisi de nous transmettre votre demande par courrier...</h2>
        <p>Tout d'abord, nous vous remercions d'avoir choisi le produit livret<br />
          Pour finaliser votre demande, vous devez télécharger votre dossier pré-renseigné des informations que vous nous avez indiquées.</p>
        <div className="pdf-section">
          <a onClick={() => handleDownloadOffreLivretOnClick()}>
            <img src={pdf} alt="BFM_Offre_Livret.pdf" className="pdf-icon" />
            <p>BFM_Offre_Livret.pdf</p>
          </a>
        </div>
        <div className="instructions-section">
          <p>Quelles sont les prochaines étapes ?<br />
            1. Après l'avoir imprimé, paraphez chacune des pages et signez-le.<br />
            2. Envoyez-nous l'ensemble de votre dossier par courrier, à l'adresse postale suivante :</p>
          <p className="address-section">
            <p>Banque Française Mutualiste<br />
              Service xxxx<br />
              56-60 rue de la Glacière<br />
              75 013 Paris</p>
          </p>
          <p>3. A réception de votre dossier complet, nous traiterons votre demande et vous ferons un retour par mail dans un délai de x jours.</p>
          <p className="thanks-section">MERCI de votre confiance !</p>
        </div>
      </div>
    </>
  ) : generateDocumentADGResponse === false || error || client.lC_GUID == "00000000-0000-0000-0000-000000000000" ?
    <ErrorComponent
      errorMessage={'Veuillez réessayer ultérieurement, le service est momentanément indisponible'}
      displayButton={true}
      buttonText={'Retour à la page précédente'}
      step={'/step14'} />
    : <Loader></Loader>
}
export default PaperSignatureStep