export const emptyDetails = {
  role: '',
  organization: '',
  startDate: '',
  endDate: '',
  country: ''
}
export const emptyExposedPerson = {
  lastName: '',
  firstName: '',
  birthdate: '',
  birthPlace: '',
  role: '',
  organization: '',
  startDate: '',
  endDate: '',
  country: '',
  natureOfRelationship: ''
}