import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { isNullOrWhiteSpace } from "../utils/string";

export const validateMandatory = (client: Client): boolean => {
  let clientTaxUSA = client.lIVRET_AUTRE_RESID_FISCALES.find(x=>x.index==3);
  return client.lC_EST_RESD_FISCALE_FRANCE == true
    && (client.lC_EST_CITOYEN_USA == false)
    || (client.lC_EST_CITOYEN_USA == true && !isNullOrWhiteSpace(clientTaxUSA?.arF_IDENTIFIANT_FISCALE))
    || (client.lC_EST_CITOYEN_USA == true && !clientTaxUSA?.arF_IDENTIFIANT_EXISTE && !isNullOrWhiteSpace(clientTaxUSA?.arF_MOTIF_NIF))                                                                       
    && (client.lC_EST_RESD_FISCALE_AUTRES == false
    || (client.lC_EST_RESD_FISCALE_AUTRES == true
    && client.lIVRET_AUTRE_RESID_FISCALES.every(obj => !isNullOrWhiteSpace(obj.arF_IDENTIFIANT_FISCALE) && obj.arF_PAYS || !obj.arF_IDENTIFIANT_EXISTE && !isNullOrWhiteSpace(obj.arF_MOTIF_NIF) && obj.arF_PAYS)));
}

export const validate = (client: Client): StepValidatorModel => {
  let tempsErrors: FormsErros = {};
  let clientTaxUSA = client.lIVRET_AUTRE_RESID_FISCALES.find(x=>x.index==3);

  if (client.lC_EST_CITOYEN_USA && clientTaxUSA?.arF_IDENTIFIANT_FISCALE) {
    if (!/^[a-z0-9]+$/i.test(clientTaxUSA.arF_IDENTIFIANT_FISCALE)) {
      tempsErrors.UsCitizenFiscalIdValidator = "Votre identifiant ne doit comporter que des chiffres et des lettres";
    } else if (clientTaxUSA.arF_IDENTIFIANT_FISCALE.length > 20) {
      tempsErrors.UsCitizenFiscalIdValidator = "Votre identifiant est trop long";
    }
  }

  if (client.lC_EST_CITOYEN_USA && !clientTaxUSA?.arF_IDENTIFIANT_EXISTE && isNullOrWhiteSpace(clientTaxUSA?.arF_MOTIF_NIF)) {
    tempsErrors.UsCitizenMotifNifValidator = "Veuillez sélectionner un motif.";
  }

  if (client.lC_EST_RESD_FISCALE_AUTRES) {
    client.lIVRET_AUTRE_RESID_FISCALES.map((obj, index) => {
      if (index === 0) {
        if (obj.arF_IDENTIFIANT_FISCALE && !/^[a-z0-9]+$/i.test(obj.arF_IDENTIFIANT_FISCALE)) {
          tempsErrors.TaxObligationsOtherCountryIdentifier0Validator = "Votre identifiant ne doit comporter que des chiffres et des lettres";
        } else if (obj.arF_IDENTIFIANT_FISCALE.length > 20) {
          tempsErrors.TaxObligationsOtherCountryIdentifier0Validator = "Votre identifiant est trop long";
        }
        if(!obj.arF_IDENTIFIANT_EXISTE && isNullOrWhiteSpace(obj.arF_MOTIF_NIF)){
          tempsErrors.TaxMotifNif0Validator = "Veuillez sélectionner un motif.";
        }
      }

      if (index === 1) {
        if (obj.arF_IDENTIFIANT_FISCALE && !/^[a-z0-9]+$/i.test(obj.arF_IDENTIFIANT_FISCALE)) {
          tempsErrors.TaxObligationsOtherCountryIdentifier1Validator = "Votre identifiant ne doit comporter que des chiffres et des lettres";
        } else if (obj.arF_IDENTIFIANT_FISCALE.length > 20) {
          tempsErrors.TaxObligationsOtherCountryIdentifier1Validator = "Votre identifiant est trop long";
        }
        if(!obj.arF_IDENTIFIANT_EXISTE && isNullOrWhiteSpace(obj.arF_MOTIF_NIF)){
          tempsErrors.TaxMotifNif1Validator = "Veuillez sélectionner un motif.";
        }
      }

      if (index === 2) {
        if (obj.arF_IDENTIFIANT_FISCALE && !/^[a-z0-9]+$/i.test(obj.arF_IDENTIFIANT_FISCALE)) {
          tempsErrors.TaxObligationsOtherCountryIdentifier2Validator = "Votre identifiant ne doit comporter que des chiffres et des lettres";
        } else if (obj.arF_IDENTIFIANT_FISCALE.length > 20) {
          tempsErrors.TaxObligationsOtherCountryIdentifier2Validator = "Votre identifiant est trop long";
        }
        if(!obj.arF_IDENTIFIANT_EXISTE && isNullOrWhiteSpace(obj.arF_MOTIF_NIF)){
          tempsErrors.TaxMotifNif2Validator = "Veuillez sélectionner un motif.";
        }
      }
    })
  }

  let isValid = Object.values(tempsErrors).every(x => x === "")
  let stepValidatorModel: StepValidatorModel = {
    isValid: !isValid,
    errors: tempsErrors
  };
  return stepValidatorModel;
}
