import { useState } from "react";
import { Outlet } from 'react-router-dom'
import Stepper from '../components/Stepper'
import ProgressBar from '../components/ProgressBar'
import { useClient } from '../contexts/LivretMainContext'
import paramFooter from "../types/paramFooter";
import Popup from "../components/Popup";
import { useGetParamFooter } from "../hooks/ClientHooks";

function StepperLayout() {
  const { data } = useGetParamFooter();
  const { progress, subStep } = useClient();
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false);
  const [pramFooter, setparampramFooter] = useState<paramFooter>();
  const [popupTitle, setPopupTitle] = useState<string>("");

  const togglePopup = () => {
    setIsVisiblePopup(!isVisiblePopup);
  }

  const OnRedirectClick = (title: string) => {
    if (data) {
      setparampramFooter(data.find(pp => pp.pF_TYPE === 1));
    }
    setPopupTitle(title);
    togglePopup();
  }
  return (
    <>
      <div className="form-container">
        <div className="sidebar">
          <ProgressBar value={progress} className={"mobile"} />
          <Stepper className={`${subStep > 12 ? 'no-progress' : ''}`} />
        </div>
        <Outlet />
      </div>
      {subStep == 1 && <div className="helper-container">
        <div className={`main-helper`}>
          <h3>Protection des données à caractère personnel</h3>
          <div className="content">L’ensemble des données signalées par un astérisque est obligatoire. A défaut votre demande de souscription ne pourra aboutir.
            La Banque Française Mutualiste, responsable de traitement collecte des données vous concernant, nécessaires à l’exécution de mesures
            précontractuelles prises dans le cadre de votre demande de souscription du livret BFM Avenir.
            Conformément à la loi n°78-17 du 16 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés, vous pouvez à tout moment
            et dans les conditions prévues par la loi accéder aux informations vous concernant, demander à les faire rectifier, exercer votre droit à l’oubli
            à condition qu’il n’existe pas de motif légitime impérieux pour le traitement, en demander la limitation et la portabilité ou communiquer des
            directives sur le sort de ces données en cas de décès. Vous pouvez également à tout moment et sans justification, vous opposer à l’utilisation
            de vos données à des fins de prospection commerciale par la Banque Française Mutualiste.
            Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits <a onClick={() => OnRedirectClick("Données personnelles")} >cliquez ici</a></div>
        </div>
        <p>(1) Le Livret BFM Avenir est un compte d’épargne proposé et géré par la Banque Française Mutualiste.</p>
        <p className="mb-5">(2) Taux nominal annuel brut en vigueur au 01/07/2024, susceptible de variations. Les intérêts sont calculés par quinzaines et crédités sur le livret chaque année en janvier, et génèrent eux-mêmes des intérêts.
          Intérêts versés soumis à la source aux prélèvements sociaux au taux de 17,2% et à prélèvement à titre d’acompte d’impôt sur le revenu au taux forfaitaire de 12,8%, ouvrant droit à un crédit d’impôt.
          Sous réserve du respect de conditions de revenus et de délais imposées par la réglementation, il est possible de demander une dispense d’acompte. Les intérêts sont soumis à l’impôt sur le revenu.</p>
      </div>}

      <Popup title={popupTitle} content={''} htmlContent={pramFooter?.pF_TEXTE || ""} updateDate={pramFooter?.pF_DATEMISEAJOUR} visible={isVisiblePopup} onClose={() => togglePopup()} />
    </>
  )
}

export default StepperLayout