import { Client } from "../types/client";
import { isNullOrWhiteSpace } from "../utils/string";

export const validateMandatory = (client: Client): boolean => {
    return (
        client.lC_SITUATION_PRO !== -1 &&
        (!showOccupationStatus.includes(client.lC_SITUATION_PRO) || client.lC_PROFESSION !== -1) &&
        client.lC_CSP_CODE !== -1 &&
        !isNullOrWhiteSpace(client.lC_INTITULE_POSTE)
    );
};

const showOccupationStatus = [0, 1, 2];