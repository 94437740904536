import PropTypes from 'prop-types'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import { useAddClient, useUpdateClient } from "../hooks/ClientHooks";
import { useClient } from '../contexts/LivretMainContext';

function BottomNavigation({
  prev = "/step1",
  prevDisabled = false,
  prevLabel = 'Précédent',
  next = "/step2",
  nextDisabled,
  nextLabel = 'Suivant'
}
) {
  const addClientMutation = useAddClient(next);
  const UpdateClientMutation = useUpdateClient(next);
  const { client, subStep } = useClient();
  const navigate = useNavigate()

  const SubmitAndGoNext = (next) => {
    if (client.lC_GUID !== "00000000-0000-0000-0000-000000000000") {
      UpdateClientMutation.mutate(client)
    } else {
      if (subStep === 1) {
        navigate(next);
      } else if (subStep == 2) {
        addClientMutation.mutate(client)
      }
    }
  }

  return (
    <div className="navigation-container">
      <div className={`content${prevDisabled ? ' prev-disabled' : ''}`}>
        <Button type="prev" disabled={prevDisabled} onClick={() => navigate(prev)}>{prevLabel}</Button>
        {nextDisabled ? (
          <Button type="next" disabled={nextDisabled}>{nextLabel}</Button>) :
          (<Button type="next" disabled={nextDisabled} onClick={() => SubmitAndGoNext(next)}>{nextLabel}</Button>)}
      </div>
    </div>
  )
}

BottomNavigation.propTypes = {
  prev: PropTypes.string,
  prevDisabled: PropTypes.bool,
  prevLabel: PropTypes.string,
  next: PropTypes.string,
  nextDisabled: PropTypes.bool,
  nextLabel: PropTypes.string
}

export default BottomNavigation