import Button from "./Button";
import { PopupType } from "../interface";
import { getformattedDate } from "../utils/string";
import close from "../assets/icons/close.svg";
import DOMPurify from 'dompurify'

function Popup({ title,
    content,
    htmlContent,
    visible,
    updateDate,
    icon,
    hasHeader = true,
    hasFooter = true,
    width = '50%',
    height = '90%',
    useOverlay = true,
    onClose,
    onOk,
    closeLabel="Fermer",
    okLabel="OK",
    okButtontype="primary",
    closeButtontype="cancel"

}: PopupType) {

    const createMarkup = (html:string) => {
        return { __html: DOMPurify.sanitize(html) }

    }
    if (visible) {
        return (
            <>
                <div className="modal-overlay" style={{ background: useOverlay ? 'rgba(0, 0, 0, 0.7)' : 'transparent' }}>
                    <div className="modal-box" style={{ width: width, height: height }}>
                        {hasHeader &&
                            <div className="modal_header">
                                <h4 style={{ padding: htmlContent ? '0px 15pt 0px 15pt' : '',color: htmlContent ? '#157ec2' : ''}}>{title}</h4>
                                {onClose && <Button type="basic" onClick={onClose}>
                                    <img className='close-icon' src={close} alt={"close"} />
                                </Button>}
                            </div>
                        }
                        <div className="modal_content">
                            {icon &&
                                <img className='helper-icon' src={icon} alt={`icon ${icon}`} />
                            }
                            {htmlContent && <div className="modal_text">
                                <div dangerouslySetInnerHTML={createMarkup(htmlContent)}></div>
                                {htmlContent && updateDate && <div><span style={{ fontWeight: 'bold' }}>Dernière mise à jour :</span> {getformattedDate(updateDate)}</div>}
                            </div>}
                            {content && <div className="modal_text">{content}</div>}

                        </div>
                        <div className="modal_footer">

                            {hasFooter &&
                                <>
                                    {onClose && <Button type= {closeButtontype} onClick={onClose}>{closeLabel}</Button>}
                                    {onOk && <Button type={okButtontype} onClick={onOk}>{okLabel}</Button>}
                                </>
                            }
                        </div>


                    </div>
                </div>
            </>

        )
    }

    return null;
}

export default Popup;