export interface GenerateSignatureDocument {
    clientGuid: string,
    signatureType: string,
}


class GenerateSignatureDocumentRequest {
    clientGuid: string;
    signatureType: string;

    constructor(props: GenerateSignatureDocument) {
        this.clientGuid = props.clientGuid;
        this.signatureType = props.signatureType;
    }

}


export default GenerateSignatureDocumentRequest;