import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";

export const validateMandatory = (client: Client): boolean => {
    return client.lC_PPREMIER_VERSEMENT != null && client.lC_PPREMIER_VERSEMENT != undefined &&
        client.lC_PPREMIER_VERSEMENT >= 10 && client.lIVRET_CLIENT_ORIGINES_FONDS != null &&
        client.lIVRET_CLIENT_ORIGINES_FONDS != undefined && client.lIVRET_CLIENT_ORIGINES_FONDS.length > 0
        && client.lIVRET_CLIENT_ORIGINES_FONDS.filter(x => x.lcoF_ORIGINES_FONDS == 5).every(obj => obj.lcoF_AUTRES_FONDS != null && obj.lcoF_AUTRES_FONDS.trim() !== '');
}

export const validate = (client: Client): StepValidatorModel => {
    let tempsErrors: FormsErros = {};
    if (client.lC_PPREMIER_VERSEMENT && !/^\d+$/.test(client.lC_PPREMIER_VERSEMENT.toString())) {
        tempsErrors.initialPaymentAmountValidator = "Votre devez saisir un montant";
    } else if (client.lC_PPREMIER_VERSEMENT && client.lC_PPREMIER_VERSEMENT < 10) {
        tempsErrors.initialPaymentAmountValidator = "Le montant que vous avez saisi est inférieur au minimum possible au produit";
    }

    let isValid = Object.values(tempsErrors).every(x => x === "")
    let stepValidatorModel: StepValidatorModel = {
        isValid: !isValid,
        errors: tempsErrors
    };
    return stepValidatorModel;
}



