import React from 'react';
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider, addLocale } from 'primereact/api';
import App from './App';
// import 'bootstrap/dist/css/bootstrap.css'
// import './index.css';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import './assets/styles/index.scss'

import { fr } from './assets/locale.js';
import { MainContext, MainContextProvider } from './contexts/mainContext.jsx';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ClientProvider } from './contexts/LivretMainContext';
import { AuthGuard } from './contexts/AuthGuard';
//import reportWebVitals from './reportWebVitals';
addLocale('fr', fr);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <MainContextProvider >
        <ClientProvider>
        <PrimeReactProvider value={{locale: 'fr'}}>
        <QueryClientProvider client={queryClient}>
          {/* <AuthGuard> */}
            <App />
          {/* </AuthGuard> */}
          </QueryClientProvider>
        </PrimeReactProvider>
        </ClientProvider>
      </MainContextProvider>
    </BrowserRouter>
);

//reportWebVitals();
