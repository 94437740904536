import { Calendar as PrimeCalendar } from 'primereact/calendar'
import { useRef } from 'react'

import { CalendarType, CustomPrimeCalendarType } from '../interface'

function Calendar({
  name,
  value,
  onChange,
  label,
  required = false,
  error = false,
  errorMsg = '',
  valid = false,
  className = ''
}: CalendarType) {

    const formCalendarGroup = useRef<HTMLDivElement>(null)
    const calendarRef = useRef<CustomPrimeCalendarType>(null)

    const handleChange = (e: React.FormEvent<Element>, fromChange?: boolean) => {
      const target = e.target as HTMLInputElement;
      if (!target.value || (target.value || '').length === 0) {
        formCalendarGroup.current?.classList.remove('focus')
      } else {
        formCalendarGroup.current?.classList.add('focus')
      }
      
      if (fromChange) {
        onChange(e)
      }
    }

    const focusCalendar = () => {
      if (!calendarRef.current) return 
      calendarRef.current.focus()
    }

  return (
    <div className={`form-calendar-group ${className} ${value ? 'focus' : ''}`} ref={formCalendarGroup}>
      <div onClick={focusCalendar}>
        <PrimeCalendar
          value={value as Date}
          showIcon
          dateFormat="dd/mm/yy"
          ref={calendarRef}
          onChange={(e: unknown) => handleChange(e as React.FormEvent<Element>, true)}
          onFocus={(e) => handleChange(e)}
          onBlur={(e) => handleChange(e)}
        />
      </div>
      <label htmlFor={name} className="form_label" onClick={focusCalendar}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      {valid && <span className="form_input--valid"><i className="pi pi-check" style={{color: '#1AE451'}}/></span>}
      {error && <span className="form_input--error"><i className="pi pi-times" style={{color: '#DC4D57'}}/></span>}
      {error && <span className="form_calendar-error-message text-white" style={{visibility:'visible'}}>{errorMsg}</span>} 
    </div>
  )
}

export default Calendar