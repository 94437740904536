import { useEffect, useState } from "react";
import paramfaQResponse from "../types/paramFAQ";
import axiosInstance from "../Interceptors/axiosconfig";

function FAQ() {

  const [faqs, setFaqs] = useState<paramfaQResponse[]>([]);
  useEffect(() => {
    axiosInstance.get(`/Param/GetParamFAQ`).then((resp) => setFaqs(resp.data))
  }, []);
  return <>
    <div className="faq-container">
      <h2> Foire aux questions </h2>
      <div className="tabs">
        {faqs?.map(item =>
          <div className="tab" key={item.faQ_ID}>
            <input type="checkbox" id={"check-" + item.faQ_ID} />
            <label className="tab-label" htmlFor={"check-" + item.faQ_ID}>{item.faQ_QUESTION}</label>
            <div className="tab-content" dangerouslySetInnerHTML={{ __html: item.faQ_RESPONSE }}>
            </div>
          </div>
        )}
      </div>
      <p style={{ textAlign: 'center' }}>(1) Le Livret BFM Avenir est un compte d’épargne proposé et géré par la Banque Française Mutualiste.</p>
    </div>
  </>
}

export default FAQ