import PropTypes from 'prop-types';
import { ReactComponent as Perso } from '../assets/icons/stepper/perso.svg?react';
import { ReactComponent as Situation} from '../assets/icons/stepper/situation.svg?react';
import { ReactComponent as Justificatifs} from '../assets/icons/stepper/justificatifs.svg?react';
import { ReactComponent as Epargne} from '../assets/icons/stepper/epargne.svg?react';
import { useClient } from '../contexts/LivretMainContext';

function Stepper({ className = '' }) {
  const { mainStep, stepsLabels } = useClient();

  return (
    <div className={`stepper-container ${className}`}>
      <div className={`stepper step-${mainStep}`}>
        {stepsLabels.map((step, index) => <Step key={index} index={index} activeStep={mainStep} step={step} totalStep={(stepsLabels || []).length}/>)}
      </div>
    </div>
  )
}

const Step = ({ step, index, activeStep, totalStep }) => {
  const currentIndex = index + 1
  return (
  <div className={`step${currentIndex < activeStep ? ' already-active': ''}${currentIndex === activeStep ? ' active': ''}`}>
    <div className="icon">
      { currentIndex === 1 ? <Perso /> : ''}
      { currentIndex === 2 ? <Situation /> : ''}
      { currentIndex === 3 ? <Epargne /> : ''}
      { currentIndex === 4 ? <Justificatifs /> : ''}
    </div>
    <div className="step-content">
      <h3>Étape {currentIndex}<span className="mobile">/{totalStep}</span></h3>
      <p>{step}</p>
    </div>
  </div>
  )
}

Stepper.propTypes = {
  className: PropTypes.string
}

Step.propTypes = {
  step: PropTypes.string,
  index: PropTypes.number,
  activeStep: PropTypes.number,
  totalStep: PropTypes.number
}

export default Stepper