import { useContext, useEffect, useState } from 'react'
import { MainContext } from '../contexts/mainContext'
import { useClient } from "../contexts/LivretMainContext";
import axiosInstance from '../Interceptors/axiosconfig';
import Loader from '../components/Loader';
import ErrorComponent from '../components/ErrorComponent';


function ElectronicSignatureStep() {
  const { client } = useClient();
  const { setSubStep } = useContext(MainContext);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setSubStep('')
  }, [setSubStep]);


  const getElectronicSignatureUrl = async () => {
    try {
      const response = await axiosInstance.post(`/ElectronicSignature/GetLivretContractToSign?clientGuid=${client.lC_GUID}`);
      setUrl(response.data);
      setError(false);
    }
    catch (err) {
      setError(true);
    }
  }

  useEffect(() => {
    getElectronicSignatureUrl();
  }, []);

  return url ? (
    <div>
      <iframe name="BFM Livret Offre"
        src={url}
        sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin"
        width={"100%"}
        height={"1000px"} />
    </div>
  ) : error ?
    <ErrorComponent
      errorMessage={'Veuillez réessayer ultérieurement, le service est momentanément indisponible'}
      displayButton={true}
      buttonText={'Retour à la page précédente'}
      step={'/step13'} />

    : <Loader></Loader>
}
export default ElectronicSignatureStep