import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { isNullOrWhiteSpace } from "../utils/string";

export const validateMandatory =(client:Client): boolean=>{
   return !isNullOrWhiteSpace(client.lC_COORD_ADR1)
    && !isNullOrWhiteSpace(client.lC_COORD_CP)
    && !isNullOrWhiteSpace(client.lC_COORD_VILLE)
    && (client.lC_HEBERGEMENT_TIERS != null && client.lC_HEBERGEMENT_TIERS != undefined);
}

export const validate = (client:Client) : StepValidatorModel => {
  let tempsErrors : FormsErros = {};
  
  if(client.lC_COORD_ADR1.length > 50){
    tempsErrors.Address1Validator = "Votre adresse doit comporter au plus 50 caractères";
  }

  if(client.lC_COORD_ADR2.length > 50){
    tempsErrors.Address2Validator = "Votre adresse doit comporter au plus 50 caractères";
  }

  if(client.lC_COORD_CP.length > 5){
    tempsErrors.ZipCodeValidator = "Votre code postale doit comporter au plus 5 caractères";
  }else if(client.lC_COORD_CP && !validateZipCode(/^\d+$/,client.lC_COORD_CP)){
    tempsErrors.ZipCodeValidator = "Votre code postale doit être composé uniquement de chiffres";
  }

  if(client.lC_COORD_VILLE.length > 32){
    tempsErrors.CityValidator = "Votre localité doit comporter au plus 32 caractères";
  }
 
  let isValid= Object.values(tempsErrors).every(x => x === "") 
  let stepValidatorModel : StepValidatorModel = {
    isValid:!isValid,
    errors :tempsErrors
  };
  return stepValidatorModel;
}

const validateZipCode = (regx : RegExp,phoneNumber:string) : boolean => {
  return regx.test(phoneNumber);
}
