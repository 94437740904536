export const calculateClientAge = (birthDate) => {
   var age = 0;
   var currentDate = new Date();
   var currentDateYear = currentDate.getFullYear();
   var currentDateMonth = currentDate.getMonth() + 1;
   var currentDateDay = currentDate.getDate();
   var birthDateYear = birthDate.getFullYear();
   var birthDateMonth = birthDate.getMonth() + 1;
   var birthDateDay = birthDate.getDate();

   // Futur date are not allowed 
   if (birthDateYear > currentDateYear
      || (birthDateYear === currentDateYear && birthDateMonth > currentDateMonth)
      || (birthDateYear === currentDateYear && birthDateMonth === currentDateMonth && birthDateDay > currentDateDay)) {
      return -1;
   }

   age += currentDateYear - birthDateYear;

   if (birthDateMonth > currentDateMonth || (birthDateMonth === currentDateMonth && birthDateDay > currentDateDay
      && (birthDateMonth !== 2 || (birthDateMonth === 2 && birthDateDay !== 29 && currentDateDay !== 28)))) {
      age -= 1;
   }

   return age;

}

export const convertSecondsTOMinutes = (timeInSeconds) => {
   const minutes = Math.floor(timeInSeconds / 60);
   const seconds = timeInSeconds % 60;
   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};