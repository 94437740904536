import { createContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { emptyDetails, emptyExposedPerson } from './formDefault'

const defaultForm = {
  civility: '',
  lastName: '',
  firstName: '',
  birthDate: '',
  partnership: false,
  email: '',
  confirmEmail: '',
  phoneNumber: '',
  communication: false,
  street: '', 
  apartmentNumber: '', 
  zipCode: '', 
  city: '', 
  hostedByThirdParty: false,
  taxResidenceFrance: true,
  taxObligationsOtherCountry: false,
  taxObligationsOtherCountries: [{ fiscalId: '', country: ''}],
  usCitizen: false,
  usCitizenFiscalId: '',
  birthCountry: '',
  birthZipCode: '',
  birthCity: '',
  nationality: '',
  maritalStatus: '',
  employmentStatus: '',
  occupation: '',
  jobTitle: '',
  personallyExposed: false,
  importantRoleLast12Months: false,
  importantRoleLast12MonthsDetails: {...emptyDetails},
  inContactWithExposedPerson: false,
  exposedPerson: {...emptyExposedPerson},
  monthlyIncome: '',
  financialAsset: '',
  realEstateAsset: '',
  initialPaymentAmount: '',
  sourceOfFunds: [],
  otherSourceOfFunds: ''
}
const tempData = {"civility":"monsieur","lastName":"Test","firstName":"kevin","birthDate":"2024-02-27T23:00:00.000Z","partnership":false,"email":"kevin@test.fr","confirmEmail":"kevin@test.fr","phoneNumber":"0909090909","communication":true,"street":"285, avenue de la Liberté","apartmentNumber":"","zipCode":"75001","city":"Paris","hostedByThirdParty":true,"taxObligationsOtherCountry":true,"taxObligationsOtherCountries":[{"fiscalId":"345678","country":"CH"}],"usCitizen":false,"usCitizenFiscalId":"","birthCountry":"","birthZipCode":"75001","birthCity":"Paris","nationality":"FR","maritalStatus":"single","employmentStatus":"active","occupation":"administrativeBusinessRole","jobTitle":"Job de test","personallyExposed":false,"importantRoleLast12Months":false,"importantRoleLast12MonthsDetails":{"role":"","organization":"","startDate":"","endDate":"","country":""},"inContactWithExposedPerson":false,"exposedPerson":{"lastName":"","firstName":"","birthdate":"","birthPlace":"","role":"","organization":"","startDate":"","endDate":"","country":"","natureOfRelationship":""},"monthlyIncome":"income3001to5000","financialAsset":"financialAsset100000to300000","realEstateAsset":"realEstateAsset300000to600000","initialPaymentAmount":"100000","sourceOfFunds":["savings","other"],"otherSourceOfFunds":"vente de voiture","taxResidenceFrance":true}

const stepsLabels = [
  'Vos informations personnelles.',
  'Votre situation et vos finances',
  'Votre épargne souhaitée',
  'Vos pièces justificatives'
]

const totalSubStep = 12

export const MainContext = createContext();

export function MainContextProvider({ children }) {
  const [formInput, setFormInput] = useState({...defaultForm, ...tempData});
  const [subStep, setSubStep] = useState(1);
  const [mainStep, setMainStep] = useState(3);
  const [progress, setProgress] = useState(0);

  const updateFormInput = (key, value) => {
    setFormInput({...formInput, [key]: value })
  }

  const calcMainSteps = (currentStep) => {
    if (currentStep > 11) {
      setMainStep(4)
    } else if (currentStep === 11) {
      setMainStep(3)
    } else if (currentStep > 5 && currentStep <= 10) {
      setMainStep(2)
    } else {
      setMainStep(1)
    }
  }

  const calcProgress = (currentStep) => {
    setProgress(Math.round(((currentStep - 1) / totalSubStep) * 100))
  }

  useEffect(() => {
    calcMainSteps(subStep)
    calcProgress(subStep)
  }, [subStep]);

  return (
    <MainContext.Provider value={{
      formInput,
      updateFormInput,
      mainStep,
      stepsLabels,
      subStep,
      setSubStep,
      progress
    }}>
      {children}
    </MainContext.Provider>
    )
}

MainContextProvider.propTypes = {
  children: PropTypes.object
}