import Input from '../components/Input'
import BottomNavigation from '../components/BottomNavigation'
import Select from '../components/Select'
import { useEffect, useState } from 'react';
import { employmentStatus, occupation } from '../utils/labels';
import { csp } from '../components/types/csp';
import { useClient } from '../contexts/LivretMainContext';
import { validateMandatory } from '../services/ValidateStep7Service';

function Step7() {

  const { client, setClient, setSubStep, mainErrors, setMainErrors } = useClient();
  const [canProceed, setCanProceed] = useState(false);

  const handleEmploymentStatusChange = (e: any) => {
    const selectedValue = e.target.value;
    if(selectedValue != -1){
      setClient({ ...client, lC_SITUATION_PRO: Number(e.target.value), lC_PROFESSION: -1 });
    }
    else {
      setClient({ ...client, lC_SITUATION_PRO: -1, lC_PROFESSION: -1 });
    }
  };

  const handleCSPChange = (e: any) => {
    let libelle = csp.find(c => c.code == Number(e.target.value))?.libelle;
    setClient({ ...client, lC_CSP_CODE: Number(e.target.value), lC_CSP_LIBELLE: libelle! });
  };

  const showOccupationStatus = [0, 1, 2];

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    setCanProceed(!validMandatory);
  }, [client])

  useEffect(() => {
    setSubStep(7)
  }, [setSubStep]);

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Votre situation professionnelle</h2>
          <div className="form-content">
            <Select
              name="lC_SITUATION_PRO"
              error={client.lC_SITUATION_PRO === -1}
              valid={client.lC_SITUATION_PRO !== -1}
              onChange={(e) => handleEmploymentStatusChange(e)}
              label="Situation professionnelle"
              required
              value={client.lC_SITUATION_PRO}
            >
              <option value="-1"></option>
              {Object.entries(employmentStatus).map(([key, value], i) => <option value={key} key={i}>{value}</option>)}
            </Select>
            {
              showOccupationStatus.includes(client.lC_SITUATION_PRO!) &&
              <Select
                name="lC_PROFESSION"
                error={client.lC_PROFESSION === -1}
                valid={client.lC_PROFESSION !== -1}
                onChange={(e) => { setClient({ ...client, lC_PROFESSION: Number(e.target.value) }) }}
                label="Type de contrat"
                required
                value={client.lC_PROFESSION}
              >
                <option value="-1"></option>
                {Object.entries(occupation).filter(([_, { employmentStatus }]) =>
                  employmentStatus.includes(client.lC_SITUATION_PRO!)).map(([key, value], i) =>
                    <option value={key} key={i}>{value.label}</option>)
                }
              </Select>
            }

            <Select
              name="lC_CSP_CODE"
              error={client.lC_CSP_CODE === -1}
              valid={client.lC_CSP_CODE !== -1}
              onChange={(e) => { handleCSPChange(e) }}
              label="Catégorie Socio-Professionelle"
              required
              value={client.lC_CSP_CODE}
            >
              <option value="-1"></option>
              {Object.entries(csp).map(([index, obj]) => <option value={obj.code} key={index}>{obj.libelle}</option>)}

            </Select>

            <Input
              name="lC_INTITULE_POSTE"
              onChange={(e) => setClient({ ...client, lC_INTITULE_POSTE: e.target.value })}
              label="Intitulé du poste"
              required
              value={client.lC_INTITULE_POSTE}
            />
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container">
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed}
        prev="/step6"
        next="/step8"
      />
    </>
  )
}

export default Step7
