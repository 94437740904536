import React, { useState } from "react";
import Button from "./Button";
import Popup from "./Popup";
import paramFooter from "../types/paramFooter";
import { FooterType } from "../interface";

export enum paramFooterType {
  mentionLegale,
  donneesPersonnelles,
  conditionsGeneralesDeVenteADistance,
  gestionDesCookies
}

const Footer: React.FC<FooterType > = ({ data }) => {

  const lagalNotice = "Mentions légales";
  const personalData = "Données personnelles";
  const generalConditionsOfRemoteSale = "Conditions générales de vente à distance";
  const cookiesMnagement = "Gestion des cookies";
  
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false);
  const [pramFooter, setparampramFooter] = useState<paramFooter>();
  const [popupTitle, setPopupTitle] = useState<string>("");


  const togglePopup = () => {
    setIsVisiblePopup(!isVisiblePopup);
  }

  const onFooterButtonClick = (title: string, pramFooterType: any) => {
    setparampramFooter(data.find( pp => pp.pF_TYPE  === pramFooterType));
    setPopupTitle(title);
    togglePopup();
  }


  return (
    <>
      <footer>
        <div className="content">
          <div className="links">
            <Button type="basic" onClick={() => onFooterButtonClick(lagalNotice,paramFooterType.mentionLegale)}>{lagalNotice}</Button>
            <Button type="basic" onClick={() => onFooterButtonClick(personalData, paramFooterType.donneesPersonnelles)}>{personalData}</Button>
            <Button type="basic" onClick={() => onFooterButtonClick(generalConditionsOfRemoteSale,paramFooterType.conditionsGeneralesDeVenteADistance)}>{generalConditionsOfRemoteSale}</Button>
            {/* <Button type="basic" onClick={() => onFooterButtonClick(cookiesMnagement, paramFooterType.gestionDesCookies)}>{cookiesMnagement}</Button> */}
          </div>
        </div>
      </footer>
      <Popup title ={popupTitle} content={''} htmlContent={pramFooter?.pF_TEXTE || ""} updateDate={pramFooter?.pF_DATEMISEAJOUR} visible={isVisiblePopup} onClose={() => togglePopup() } />
    </>
  )
}

export default Footer
