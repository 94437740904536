import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { isNullOrWhiteSpace, validateZipCode } from "../utils/string";

export const validateMandatory = (client: Client): boolean => {
    return (!isNullOrWhiteSpace(client.lC_PAYS_NAISSANCE)
        && (client.lC_PAYS_NAISSANCE !== 'FR' || !isNullOrWhiteSpace(client.lC_CP_NAISSANCE))
        && !isNullOrWhiteSpace(client.lC_VILLE_NAISSANCE)
        && !isNullOrWhiteSpace(client.lC_NATIONALITE));
}

export const validate = (client: Client): StepValidatorModel => {
    let tempsErrors: FormsErros = {};

    if (!validateZipCode(client.lC_CP_NAISSANCE)) {
        tempsErrors.BirthZipCodeValidator = "Le code postal est invalide"
    } else if (client.lC_CP_NAISSANCE.length > 5) {
        tempsErrors.BirthZipCodeValidator = "Le Code postale ne doit pas dépasser 5 caractères";
    }
    
    let isValid = Object.values(tempsErrors).every(x => x === "")
    let stepValidatorModel: StepValidatorModel = {
        isValid: !isValid,
        errors: tempsErrors
    };
    return stepValidatorModel;
}
