import DownloadOffer from "../components/DownloadOffer";
import { useEffect, useState } from "react";
import { useClient } from "../contexts/LivretMainContext";
import axiosInstance from "../Interceptors/axiosconfig";
import Loader from "../components/Loader";
import errorIcon from "../assets/icons/Error.svg";
import ErrorComponent from "../components/ErrorComponent";

export enum ElectronicSignatureStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  ARCHIVED = "ARCHIVED",
  ABANDONED = "ABANDONED",
}

function FinalStep() {
  const { setSubStep } = useClient();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  if (!token) throw Error("Need token");
  const [status, setStatus] = useState(null);
  const finalStep = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/finalstep?token=${encodeURIComponent(token)}`;
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setSubStep(50);
  }, [setSubStep]);

  const electronicSignatureCallback = async () => {
    try {
      const response = await axiosInstance.post(`/ElectronicSignature/ElectronicSignatureCallBack?token=${encodeURIComponent(token)}`);
      setStatus(response.data);
      setError(false);
    }
    catch (err) {
      setError(true);
    }
  }

  useEffect(() => {
    if (window.parent.location.href !== finalStep) {
      // Redirection
      window.parent.location.href = finalStep;
    }
    else {
      electronicSignatureCallback();
    }
  }, []);


  return status ? (
    <>
      {status === ElectronicSignatureStatus.ABANDONED ?
        <p>Au plaisir de vous revoir!</p> :
        <DownloadOffer token={token}></DownloadOffer>
      }
    </>
  ) : error ?
    <ErrorComponent
      errorMessage={'Veuillez réessayer ultérieurement, le service est momentanément indisponible'}
      displayButton={false} /> :
    <Loader></Loader>
}

export default FinalStep;