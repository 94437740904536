import Helper from '../components/Helper'
import BottomNavigation from '../components/BottomNavigation'
import { useState, useEffect } from 'react';
import { useClient } from '../contexts/LivretMainContext';
import { validateMandatory } from '../services/ValidateStep10Service';
import Select from '../components/Select';

function Step10() {
  const { client, setClient, setSubStep, mainErrors } = useClient()
  const [canProceed, setCanProceed] = useState(false);
  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var isValid = Object.values(mainErrors).every(x => x === "");
    setCanProceed(!(isValid && validMandatory))
  }, [mainErrors]);

  // validation coté front on client change
  useEffect(() => {
    var validMandatory = validateMandatory(client)
    setCanProceed(!validMandatory);

  }, [client])
  useEffect(() => {
    setSubStep(10)
  }, [setSubStep]);

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Quel est votre patrimoine ?
          </h2>
          <div className='mb-4'>
            <Helper>
              <p>Il s&apos;agit d&apos;une estimation de l&apos;ensemble des biens détenus par votre foyer.</p>
            </Helper>
          </div>
          <div className="form-content">
            <label className='text-white'>Votre patrimoine financier :
              <Helper popup>
                <p>Exemples : produits d'épargne et d'investissement.</p>
              </Helper>
            </label>
            <Select
              name="lC_PATRIMOINE_FINANCIER"
              error={client.lC_PATRIMOINE_FINANCIER === 99}
              valid={client.lC_PATRIMOINE_FINANCIER !== 99}
              onChange={(e) => setClient({ ...client, lC_PATRIMOINE_FINANCIER: Number(e.target.value) })}
              label="Valeur de votre patrimoine financier"
              value={client.lC_PATRIMOINE_FINANCIER}
              required
            >
              <option value="99"></option>
              <option value="0">Moins de 10 000 €</option>
              <option value="1">Entre 10 000 € et 50 000 €</option>
              <option value="2">Entre 50 000 € et 100 000 €</option>
              <option value="3">Entre 100 000 € et 200 000 € </option>
              <option value="4">Entre 200 000 € et 350 000 €</option>
              <option value="5">Entre 350 000 € et 500 000 €</option>
              <option value="6">Plus de 500 000 €</option>
            </Select>
            <label className='text-white'>Votre patrimoine immobilier :
              <Helper popup>
                <p>Exemples : résidence principale, résidence secondaire, investissements locatifs</p>
              </Helper>
            </label>
            <Select
              name="lC_PATRIMOINE_IMMOBILIER"
              error={client.lC_PATRIMOINE_IMMOBILIER === 99}
              valid={client.lC_PATRIMOINE_IMMOBILIER !== 99}
              onChange={(e) => setClient({ ...client, lC_PATRIMOINE_IMMOBILIER: Number(e.target.value) })}
              label="Valeur de votre patrimoine immobilier"
              value={client.lC_PATRIMOINE_IMMOBILIER}
              required
            >
              <option value="99"></option>
              <option value="0">Moins de 10 000 €</option>
              <option value="1">Entre 10 000 € et 50 000 €</option>
              <option value="2">Entre 50 000 € et 100 000 €</option>
              <option value="3">Entre 100 000 € et 200 000 € </option>
              <option value="4">Entre 200 000 € et 350 000 €</option>
              <option value="5">Entre 350 000 € et 500 000 €</option>
              <option value="6">Plus de 500 000 €</option>
            </Select>
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container">
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed}
        prev="/step9"
        next="/step11"
      />
    </>
  )
}

export default Step10
