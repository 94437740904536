import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

axiosInstance.interceptors.request.use(
    conf => {
        const apiKey = process.env.REACT_APP_API_KEY;
        if (apiKey) {
            conf.headers["x-api-key"] = apiKey;
        }
        return conf;
    },
    error=>{
        return Promise.reject(error);
    }
);

export default axiosInstance;