function HeroHeader() {
  return (
    <div className="hero-header">
      <div className="hero-header-inner">
        <div className="hero-header-content">
          <h1>Ouvrir un</h1>
          <h1>Livret BFM Avenir<sup>(1)</sup></h1>
        </div>
        <div className="hero-header-encart">
          <h2>Livret BFM Avenir<sup>(1)</sup></h2>
          <p className="rate">
            <strong>3%</strong> <span className="rate-small">brut annuel<sup>(2)</sup></span>
          </p>
          <div className="description">
            <p>jusqu'à 3 000€ puis 0,5% au-delà de ce montant.</p>
            <p>Taux en vigueur au 01/07/2024, susceptibles de variations.</p>
            <p>Les intérêts sont soumis aux prélèvements fiscaux et sociaux.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroHeader;