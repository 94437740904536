import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { isNullOrWhiteSpace, validateLettersOnly } from "../utils/string";

export const validateMandatory = (client: Client): boolean => {
  return (client.lC_EST_PPE === false ||
    (client.lC_EST_PPE === true && (client.lC_ROLE_IMPORTANT_DERNIERS_MOIS === false ||
      (client.lC_ROLE_IMPORTANT_DERNIERS_MOIS === true && client.lIVRET_CLIENT_PPE !== null &&
        !isNullOrWhiteSpace(client.lIVRET_CLIENT_PPE.lC_PPE_FONCTION)
        && !isNullOrWhiteSpace(client.lIVRET_CLIENT_PPE.lC_PPE_PAYS)
        && !isNullOrWhiteSpace(client.lIVRET_CLIENT_PPE.lC_PPE_ORGANISME)
        && client.lIVRET_CLIENT_PPE.lC_PPE_DATE_DEBUT !== null && client.lIVRET_CLIENT_PPE.lC_PPE_DATE_DEBUT !== undefined
        && client.lIVRET_CLIENT_PPE.lC_PPE_DATE_FIN !== null && client.lIVRET_CLIENT_PPE.lC_PPE_DATE_FIN !== undefined))))
    //EST RELATION PPE ;
    && (client.lC_EST_RELATION_PPE === false ||
      (client.lC_EST_RELATION_PPE === true && client.rELATION_PPE !== null && (!isNullOrWhiteSpace(client.rELATION_PPE.rPPE_NOM)
        && (!isNullOrWhiteSpace(client.rELATION_PPE.rPPE_NOM)
          && !isNullOrWhiteSpace(client.rELATION_PPE.rPPE_FONCTION)
          && !isNullOrWhiteSpace(client.rELATION_PPE.rPPE_PAYS)
          && !isNullOrWhiteSpace(client.rELATION_PPE.rPPE_ORGANISME)
          && client.rELATION_PPE.rPPE_DATE_DEBUT !== null && client.rELATION_PPE.rPPE_DATE_DEBUT !== undefined
          && client.rELATION_PPE.rPPE_DATE_FIN !== null && client.rELATION_PPE.rPPE_DATE_FIN !== undefined))));
}

export const validate = (client: Client): StepValidatorModel => {
  let tempsErrors: FormsErros = {};

  if (client.lIVRET_CLIENT_PPE && client.lIVRET_CLIENT_PPE.lC_PPE_ORGANISME
    && !validateLettersOnly(client.lIVRET_CLIENT_PPE.lC_PPE_ORGANISME)) {
    tempsErrors.OrganizationPPEValidator = "Votre organisme doit être composé uniquement de lettres";
  }

  if (client.lIVRET_CLIENT_PPE && client.lIVRET_CLIENT_PPE.lC_PPE_ORGANISME
    && !validateLettersOnly(client.lIVRET_CLIENT_PPE.lC_PPE_ORGANISME)) {
    tempsErrors.OrganizationPPEValidator = "Votre organisme doit être composé uniquement de lettres";
  } else if (client.lIVRET_CLIENT_PPE && client.lIVRET_CLIENT_PPE.lC_PPE_ORGANISME.length > 32) {
    tempsErrors.OrganizationPPEValidator = "Votre organisme ne doit pas dépasser 32 caractères";
  }

  if (client.lIVRET_CLIENT_PPE && client.lIVRET_CLIENT_PPE.lC_PPE_DATE_FIN
    && client.lIVRET_CLIENT_PPE.lC_PPE_DATE_DEBUT && client.lIVRET_CLIENT_PPE.lC_PPE_DATE_FIN < client.lIVRET_CLIENT_PPE.lC_PPE_DATE_DEBUT) {
    tempsErrors.StartDatePPEValidator = "La date de fin doit être supérieure à la date de début";
  }

  if (client.lC_EST_RELATION_PPE && client.rELATION_PPE?.rPPE_DATE_FIN
    && client.rELATION_PPE.rPPE_DATE_DEBUT && client.rELATION_PPE.rPPE_DATE_FIN < client.rELATION_PPE.rPPE_DATE_DEBUT) {
    tempsErrors.StartDateRPPEValidator = "La date de fin doit être supérieure à la date de début";
  }

  if (client.rELATION_PPE && client.rELATION_PPE.rPPE_ORGANISME
    && !validateLettersOnly(client.rELATION_PPE.rPPE_ORGANISME)) {
    tempsErrors.OrganizationPartnerPPEValidator = "Le nom d'organisme doit être composé uniquement de lettres";
  }

  if (client.rELATION_PPE && client.rELATION_PPE.rPPE_NOM
    && !validateLettersOnly(client.rELATION_PPE.rPPE_NOM)) {
    tempsErrors.PartnerLastNameValidator = "Le nom doit être composé uniquement de lettres";
  } else if (client.rELATION_PPE && client.rELATION_PPE.rPPE_NOM.length > 32) {
    tempsErrors.PartnerLastNameValidator = "Le nom ne doit pas dépasser 32 caractères";
  }

  if (client.rELATION_PPE && client.rELATION_PPE.rPPE_PRENOM
    && !validateLettersOnly(client.rELATION_PPE.rPPE_PRENOM)) {
    tempsErrors.PartnerFirstNamePPEValidator = "Le prénom doit être composé uniquement de lettres";
  } else if (client.rELATION_PPE && client.rELATION_PPE.rPPE_PRENOM.length > 32) {
    tempsErrors.PartnerFirstNamePPEValidator = "Le prénom ne doit pas dépasser 32 caractères";
  }

  if (client.rELATION_PPE && client.rELATION_PPE.rPPE_ORGANISME
    && !validateLettersOnly(client.rELATION_PPE.rPPE_ORGANISME)) {
    tempsErrors.OrganizationPartnerPPEValidator = "Le nom d'organisme doit être composé uniquement de lettres";
  } else if (client.rELATION_PPE && client.rELATION_PPE.rPPE_PRENOM.length > 32) {
    tempsErrors.OrganizationPartnerPPEValidator = "Le nom d'organisme ne doit pas dépasser 32 caractères";
  }

  let isValid = Object.values(tempsErrors).every(x => x === "")
  let stepValidatorModel: StepValidatorModel = {
    isValid: !isValid,
    errors: tempsErrors
  };
  return stepValidatorModel;
}
