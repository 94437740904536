import { useNavigate } from "react-router-dom";
import { Client } from "./../types/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import Problem from "../types/problem";
import { useClient } from "../contexts/LivretMainContext";
import { FormsErros } from "../types/FormsErros";
import { paramFooterResponse } from "../types/paramFooter";
import axiosInstance from "../Interceptors/axiosconfig";

const useFetchClients = () => {
  return useQuery<Client[], AxiosError>({
    queryKey: ["clients"],
    queryFn: () =>
      axiosInstance.get(`/Client/GetAllClients`).then((resp) => resp.data),
  });
};

const useFetchClient = (LC_GUID: string) => {
  const { client, setClient, setMainErrors } = useClient()
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError<Problem>, string>(
    (h) => axiosInstance.get(`/Client/GetClientByGuid?guid=${LC_GUID}`).then((resp) => resp),
    {
      onSuccess: (_, c) => {
        setClient({
          ...client,
          lC_GUID: _.data.lC_GUID,
          lC_EMAIL: _.data.lC_EMAIL,
          lC_NOM: _.data.lC_NOM,
          lC_AUTRE_NOM: _.data.lC_AUTRE_NOM,
          lC_PRENOM: _.data.lC_PRENOM,
          lC_DATE_NAISSANCE: _.data.lC_DATE_NAISSANCE,
          lC_COORD_ADR1: _.data.lC_COORD_ADR1,
          lC_CP_NAISSANCE: _.data.lC_CP_NAISSANCE,
          lC_CLIENT_MAJEUR_CAPABLE: true,
          lC_DETENTEUR_LIVRET_BFM: false,
          lC_VILLE_NAISSANCE: _.data.lC_VILLE_NAISSANCE,
          lC_PAYS_NAISSANCE: _.data.lC_PAYS_NAISSANCE,
          lC_CONFIRMATION_EMAIL: _.data.lC_CONFIRMATION_EMAIL,
          lC_TELEPHONE: _.data.lC_TELEPHONE,
          lC_SITUATION_FAM: _.data.lC_SITUATION_FAM,
          lC_PATRIMOINE_FINANCIER: _.data.lC_PATRIMOINE_FINANCIER,
          lC_PATRIMOINE_IMMOBILIER: _.data.lC_PATRIMOINE_IMMOBILIER,
          lC_REVENUE_MENSUEL: _.data.lC_REVENUE_MENSUEL,
          lC_CIVILITE: _.data.lC_CIVILITE,
          lC_STATUT: _.data.lC_STATUT,
          lC_NATIONALITE: _.data.lC_NATIONALITE,
          lC_CLIENT_SG: _.data.lC_CLIENT_SG,
          lC_COORD_ADR2: _.data.lC_COORD_ADR2,
          lC_COORD_CP: _.data.lC_COORD_CP,
          lC_COORD_VILLE: _.data.lC_COORD_VILLE,
          lC_COORD_PAYS: _.data.lC_COORD_PAYS,
          lC_COMMUNIC_COMM_OK: _.data.lC_COMMUNIC_COMM_OK,
          lC_HEBERGEMENT_TIERS: _.data.lC_HEBERGEMENT_TIERS,
          lC_EST_RESD_FISCALE_FRANCE: _.data.lC_EST_RESD_FISCALE_FRANCE,
          lC_EST_RESD_FISCALE_AUTRES: _.data.lC_EST_RESD_FISCALE_AUTRES,
          lIVRET_AUTRE_RESID_FISCALES: _.data.livreT_AUTRE_RESID_FISCALES,
          lC_EST_CITOYEN_USA: _.data.lC_EST_CITOYEN_USA,
          lC_EST_PPE: _.data.lC_EST_PPE,
          lC_EST_RELATION_PPE: _.data.lC_EST_RELATION_PPE,
          lC_ID_FISCALE_USA: _.data.lC_ID_FISCALE_USA,
          lC_ROLE_IMPORTANT_DERNIERS_MOIS: _.data.lC_ROLE_IMPORTANT_DERNIERS_MOIS,
          lC_INTITULE_POSTE: _.data.lC_INTITULE_POSTE,
          lC_PROFESSION: _.data.lC_PROFESSION,
          lC_SITUATION_PRO: _.data.lC_SITUATION_PRO,
          lC_CSP_CODE: _.data.lC_CSP_CODE,
          lC_CSP_LIBELLE: _.data.lC_CSP_LIBELLE,
          lIVRET_CLIENT_PPE: _.data.lC_EST_PPE && _.data.livreT_CLIENT_PPE ? {
            lC_PPE_FONCTION: _.data.livreT_CLIENT_PPE.lC_PPE_FONCTION,
            lC_PPE_ORGANISME: _.data.livreT_CLIENT_PPE.lC_PPE_ORGANISME,
            lC_PPE_DATE_DEBUT: _.data.livreT_CLIENT_PPE.lC_PPE_DATE_DEBUT,
            lC_PPE_DATE_FIN: _.data.livreT_CLIENT_PPE.lC_PPE_DATE_FIN,
            lC_PPE_PAYS: _.data.livreT_CLIENT_PPE.lC_PPE_PAYS,
          } : null,
          rELATION_PPE: _.data.lC_EST_RELATION_PPE && _.data.relatioN_PPE ? {
            rPPE_NOM: _.data.relatioN_PPE.rppE_NOM,
            rPPE_PRENOM: _.data.relatioN_PPE.rppE_PRENOM,
            rPPE_DATE_NAISSANCE: _.data.relatioN_PPE.rppE_DATE_NAISSANCE,
            rPPE_FONCTION: _.data.relatioN_PPE.rppE_FONCTION,
            rPPE_ORGANISME: _.data.relatioN_PPE.rppE_ORGANISME,
            rPPE_DATE_DEBUT: _.data.relatioN_PPE.rppE_DATE_DEBUT,
            rPPE_DATE_FIN: _.data.relatioN_PPE.rppE_DATE_FIN,
            rPPE_PAYS: _.data.relatioN_PPE.rppE_PAYS,
            rPPE_NATURE_RELATION: _.data.relatioN_PPE.rppE_NATURE_RELATION
          } : null,
          lC_PPREMIER_VERSEMENT: _.data.lC_PPREMIER_VERSEMENT,
          lC_JUMP_EASYCONFORM_STEP: _.data.lC_JUMP_EASYCONFORM_STEP,
          lIVRET_CLIENT_ORIGINES_FONDS: _.data.livreT_CLIENT_ORIGINES_FONDS,
          lIVRET_CLIENT_DOCUMENTS: _.data.livreT_CLIENT_DOCUMENTS,
          lC_STEP: _.data.lC_STEP
        });
        queryClient.invalidateQueries("Clients");
        nav("/step1");
      },
      onError: (errors) => {
        var errorMessages: any = {}
        var erros: any = errors.response?.data;
        erros.map((er: { code: keyof FormsErros, message: string }, index: number) => (
          errorMessages[er.code] = er.message
        ));
        setMainErrors(errorMessages);
      }
    }
  );
};

const useFetchClientbyToken = (token: string) => {
  const { client, setClient, setMainErrors } = useClient()
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError<Problem>, string>(
    (h) => axiosInstance.get(`/Client/GetUserFromToken?token=${encodeURIComponent(token)}`).then((resp) => resp),
    {
      onSuccess: (_, c) => {

        setClient({
          ...client,
          lC_GUID: _.data.lC_GUID,
          lC_EMAIL: _.data.lC_EMAIL,
          lC_NOM: _.data.lC_NOM,
          lC_AUTRE_NOM: _.data.lC_AUTRE_NOM,
          lC_PRENOM: _.data.lC_PRENOM,
          lC_DATE_NAISSANCE: _.data.lC_DATE_NAISSANCE,
          lC_COORD_ADR1: _.data.lC_COORD_ADR1,
          lC_CP_NAISSANCE: _.data.lC_CP_NAISSANCE,
          lC_CLIENT_MAJEUR_CAPABLE: true,
          lC_DETENTEUR_LIVRET_BFM: false,
          lC_VILLE_NAISSANCE: _.data.lC_VILLE_NAISSANCE,
          lC_PAYS_NAISSANCE: _.data.lC_PAYS_NAISSANCE,
          lC_CONFIRMATION_EMAIL: _.data.lC_CONFIRMATION_EMAIL,
          lC_TELEPHONE: _.data.lC_TELEPHONE,
          lC_SITUATION_FAM: _.data.lC_SITUATION_FAM,
          lC_PATRIMOINE_FINANCIER: _.data.lC_PATRIMOINE_FINANCIER,
          lC_PATRIMOINE_IMMOBILIER: _.data.lC_PATRIMOINE_IMMOBILIER,
          lC_REVENUE_MENSUEL: _.data.lC_REVENUE_MENSUEL,
          lC_CIVILITE: _.data.lC_CIVILITE,
          lC_STATUT: _.data.lC_STATUT,
          lC_NATIONALITE: _.data.lC_NATIONALITE,
          lC_CLIENT_SG: _.data.lC_CLIENT_SG,
          lC_COORD_ADR2: _.data.lC_COORD_ADR2,
          lC_COORD_CP: _.data.lC_COORD_CP,
          lC_COORD_VILLE: _.data.lC_COORD_VILLE,
          lC_COORD_PAYS: _.data.lC_COORD_PAYS,
          lC_COMMUNIC_COMM_OK: _.data.lC_COMMUNIC_COMM_OK,
          lC_HEBERGEMENT_TIERS: _.data.lC_HEBERGEMENT_TIERS,
          lC_EST_RESD_FISCALE_FRANCE: _.data.lC_EST_RESD_FISCALE_FRANCE,
          lC_EST_RESD_FISCALE_AUTRES: _.data.lC_EST_RESD_FISCALE_AUTRES,
          lIVRET_AUTRE_RESID_FISCALES: _.data.livreT_AUTRE_RESID_FISCALES,
          lC_EST_CITOYEN_USA: _.data.lC_EST_CITOYEN_USA,
          lC_EST_PPE: _.data.lC_EST_PPE,
          lC_EST_RELATION_PPE: _.data.lC_EST_RELATION_PPE,
          lC_ID_FISCALE_USA: _.data.lC_ID_FISCALE_USA,
          lC_ROLE_IMPORTANT_DERNIERS_MOIS: _.data.lC_ROLE_IMPORTANT_DERNIERS_MOIS,
          lC_INTITULE_POSTE: _.data.lC_INTITULE_POSTE,
          lC_PROFESSION: _.data.lC_PROFESSION,
          lC_SITUATION_PRO: _.data.lC_SITUATION_PRO,
          lIVRET_CLIENT_PPE: _.data.lC_EST_PPE && _.data.livreT_CLIENT_PPE ? {
            lC_PPE_FONCTION: _.data.livreT_CLIENT_PPE.lC_PPE_FONCTION,
            lC_PPE_ORGANISME: _.data.livreT_CLIENT_PPE.lC_PPE_ORGANISME,
            lC_PPE_DATE_DEBUT: _.data.livreT_CLIENT_PPE.lC_PPE_DATE_DEBUT,
            lC_PPE_DATE_FIN: _.data.livreT_CLIENT_PPE.lC_PPE_DATE_FIN,
            lC_PPE_PAYS: _.data.livreT_CLIENT_PPE.lC_PPE_PAYS,
          } : null,
          rELATION_PPE: _.data.lC_EST_RELATION_PPE && _.data.relatioN_PPE ? {
            rPPE_NOM: _.data.relatioN_PPE.rppE_NOM,
            rPPE_PRENOM: _.data.relatioN_PPE.rppE_PRENOM,
            rPPE_DATE_NAISSANCE: _.data.relatioN_PPE.rppE_DATE_NAISSANCE,
            rPPE_FONCTION: _.data.relatioN_PPE.rppE_FONCTION,
            rPPE_ORGANISME: _.data.relatioN_PPE.rppE_ORGANISME,
            rPPE_DATE_DEBUT: _.data.relatioN_PPE.rppE_DATE_DEBUT,
            rPPE_DATE_FIN: _.data.relatioN_PPE.rppE_DATE_FIN,
            rPPE_PAYS: _.data.relatioN_PPE.rppE_PAYS,
            rPPE_NATURE_RELATION: _.data.relatioN_PPE.rppE_NATURE_RELATION
          } : null,
          lC_PPREMIER_VERSEMENT: _.data.lC_PPREMIER_VERSEMENT,
          lC_JUMP_EASYCONFORM_STEP: _.data.lC_JUMP_EASYCONFORM_STEP,
          lIVRET_CLIENT_ORIGINES_FONDS: _.data.livreT_CLIENT_ORIGINES_FONDS,
          lIVRET_CLIENT_DOCUMENTS: _.data.livreT_CLIENT_DOCUMENTS,
          lC_STEP: _.data.lC_STEP
        });
        queryClient.invalidateQueries("Clients");
      },
      onError: (errors) => {
        var errorMessages: any = {}
        var erros: any = errors.response?.data;
        erros.map((er: { code: keyof FormsErros, message: string }, index: number) => (
          errorMessages[er.code] = er.message
        ));
        setMainErrors(errorMessages);
      }
    }
  );
};

const useGetMunicipalities = (value: string) => {
  return useQuery<Client, AxiosError>({
    queryFn: async () =>
      await axiosInstance.get(`/Hexapost/SearchMunicipalitiesByValue?search=` + value).then((resp) => resp.data)
  });
};
const useGetVilles = (value: string) => {
  return useQuery<Client, AxiosError>({
    queryFn: async () =>
      await axiosInstance.get(`/Ville/SearchVilleByDepartement?search=` + value).then((resp) => resp.data)
  });
};


const useUpdateClient = (next: string) => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  const { setMainErrors } = useClient()
  return useMutation<AxiosResponse, AxiosError<Problem>, Client>({
    mutationFn: (h) => axiosInstance.post(`/client/UpdateClient`, h),
    onSuccess: (_, client) => {
      queryClient.refetchQueries({ queryKey: ["clients"] });
      nav(next);
    },
    onError: (errors) => {
      var errorMessages: any = {}
      var erros: any = errors.response?.data;
      erros.map((er: { code: keyof FormsErros, message: string }, index: number) => (
        errorMessages[er.code] = er.message
      ));
      setMainErrors(errorMessages);
    }
  });
};


const useAddClient = (next: string) => {
  const { client, setClient, setMainErrors } = useClient()
  const queryClient = useQueryClient();
  const nav = useNavigate();
  //AxiosResponse response of api
  //AxiosError error type
  //Client type that we want to send to to api in request body 
  return useMutation<AxiosResponse, AxiosError<Problem>, Client>(
    (h) => axiosInstance.post(`/Client/CreateClient`, h),
    {
      onSuccess: (_, c) => {
        setClient({ ...client, lC_GUID: _.data });
        queryClient.invalidateQueries("Clients");
        nav(next);
      },
      onError: (errors) => {
        var errorMessages: any = {}
        var erros: any = errors.response?.data;
        erros.map((er: { code: keyof FormsErros, message: string }, index: number) => (
          errorMessages[er.code] = er.message
        ));
        setMainErrors(errorMessages);
      }
    }
  );
};

const useGetParamFooter = () => {
  return useQuery<Array<paramFooterResponse>, AxiosError>({
    queryFn: async () =>
      await axiosInstance.get(`/Param/GetParamFooter`).then((resp) => resp.data)
  });
};

export {
  useFetchClients,
  useFetchClient,
  useFetchClientbyToken,
  useAddClient,
  useUpdateClient,
  useGetMunicipalities,
  useGetVilles,
  useGetParamFooter
};