function Loader({
  }) {

  return (
    <div className="loader">
      <div className="ring">
     </div>
    </div>
  )
}



export default Loader