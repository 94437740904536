import React from "react"
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../utils/string'

function Radio({
    name,
    label,
    required,
    onChange,
    inline = false,
    vertical = false,
    verticalWrap = false,
    value,
    className = '',
    data 
  }) {
  return (
    <div className={`form-radio-group ${className} ${inline ? 'inline' : (vertical ? 'vertical' : (verticalWrap ? 'vertical-wrap' : ''))}`}>
      <label htmlFor={name} className="form-radio_label">
      <span className="label">
        {typeof label === 'string' ? (
          <>
            {label}
            {required && <span className="required"> *</span>}
          </>
          ) : (
            label
        )}
      </span>
      </label>
      <div className="form-radio_buttons">
        {
          (data && Object.keys(data).length >= 2) ?
            Object.entries(data).map(([key, val], index) => <React.Fragment key={index}>
              <input
                type="radio"
                id={`${name}-${key}`}
                name={name}
                value={val}
                checked={key === value}
                onChange={() => onChange(key)}
              />
              <label htmlFor={`${name}-${key}`}>{capitalizeFirstLetter(val)}</label>
            </React.Fragment>
            )
          :
          <>
            <input
              type="radio"
              id={`${name}-yes`}
              name={name}
              value={true}
              checked={value === true}
              onChange={() => onChange(true)}
            />
            <label htmlFor={`${name}-yes`}>Oui</label>
            <input
              type="radio"
              id={`${name}-no`}
              name={name}
              value={false}
              checked={value === false}
              onChange={() => onChange(false)}
            />
            <label htmlFor={`${name}-no`}>Non</label>
          </>
        }
        
      </div>
    </div>
  )
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  vertical: PropTypes.bool,
  verticalWrap: PropTypes.bool,
  data: PropTypes.object
}

export default Radio