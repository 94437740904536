import { FormsErros } from "../types/FormsErros";
import { StepValidatorModel } from "../types/StepValidatorModel";
import { Client } from "../types/client";
import { calculateClientAge } from "../utils/dateTime";
import { isNullOrWhiteSpace, validateLettersOnly } from "../utils/string";

export const validateMandatory = (client: Client): boolean => {
  return !isNullOrWhiteSpace(client.lC_NOM) && !isNullOrWhiteSpace(client.lC_PRENOM)
    && client.lC_CIVILITE !== null && client.lC_CIVILITE !== undefined
    && client.lC_DATE_NAISSANCE !== null && client.lC_DATE_NAISSANCE !== undefined
    && client.lC_CLIENT_MAJEUR_CAPABLE !== null && client.lC_CLIENT_MAJEUR_CAPABLE
    && client.lC_DETENTEUR_LIVRET_BFM !== null && !client.lC_DETENTEUR_LIVRET_BFM
    && client.lC_CLIENT_SG !== true && client.lC_CLIENT_SG !== null && client.lC_CLIENT_SG !== undefined && ((client.lC_CIVILITE === "Mme" && !isNullOrWhiteSpace(client.lC_AUTRE_NOM)) || client.lC_CIVILITE !== "Mme");
}

export const validate = (client: Client): StepValidatorModel => {
  let tempsErrors: FormsErros = {};
  const legalAge = 18;

  if (client.lC_NOM && !validateLettersOnly(client.lC_NOM)) {
    tempsErrors.LastNameValidator = "Votre nom doit être composé uniquement de lettres";
  } else if (client.lC_NOM && client.lC_NOM.length > 32) {
    tempsErrors.LastNameValidator = "Votre nom ne doit pas dépasser 32 caractères";
  }

  if (client.lC_CIVILITE === "Mme" && client.lC_AUTRE_NOM && !validateLettersOnly(client.lC_AUTRE_NOM)) {
    tempsErrors.OtherLastNameValidator = "Votre nom doit être composé uniquement de lettres";
  } else if (client.lC_AUTRE_NOM && client.lC_AUTRE_NOM.length > 32) {
    tempsErrors.OtherLastNameValidator = "Votre nom ne doit pas dépasser 32 caractères";
  }

  if (client.lC_PRENOM && !validateLettersOnly(client.lC_PRENOM)) {
    tempsErrors.FirstNameValidator = "Votre prénom doit être composé uniquement de lettres";
  } else if (client.lC_PRENOM && client.lC_PRENOM.length > 32) {
    tempsErrors.FirstNameValidator = "Votre prénom ne doit pas dépasser 32 caractères";
  }

  if (client.lC_DATE_NAISSANCE && calculateClientAge(new Date(client.lC_DATE_NAISSANCE)) < legalAge) {
    tempsErrors.BirthDateValidator = " Votre date de naissance doit être celle d'une personne majeure";
  }

  let isValid = Object.values(tempsErrors).every(x => x === "")
  let stepValidatorModel: StepValidatorModel = {
    isValid: !isValid,
    errors: tempsErrors
  };
  return stepValidatorModel;
}