import { useNavigate } from "react-router-dom";
import errorIcon from "../assets/icons/Error.svg";
import Button from '../components/Button';


export interface ErrorComponentType {
    errorMessage: string;
    displayButton: boolean;
    buttonText?: string;
    step?: string;
}

const ErrorComponent: React.FC<ErrorComponentType> = ({ errorMessage, displayButton, step, buttonText }) => {

    const navigate = useNavigate();

    return (
        <>
            <div className="errorComponent-container">
                <p>{errorMessage}</p>
                <div>
                    <img src={errorIcon} style={{ height: "600px", width: "100%", maxWidth: "350px" }} alt="Error" title="Error" className="Error" />
                    {displayButton && step && buttonText &&
                        <Button type="primary-center" disabled={false} onClick={() => navigate(step)}>{buttonText}</Button>
                    }
                </div>
            </div>
        </>
    )
}

export default ErrorComponent
