export const maritalStatus = {
  0: 'célibataire',
  1: 'en concubinage',
  2: 'pacsé(e)',
  3: 'marié(e)',
  4: 'séparé(e)',
  5: 'divorcé(e)',
  6: 'veuf(ve)'
}

export const motifNif = {
  1: "Nouvelle embauche ou début d'activité",
  2: "Mutation ou détachement à l'international",
  3: "Changement de statut fiscal"
};

export const monthlyIncome = {
  0: 'inférieurs à 1 500 €',
  1: 'entre 1 501 € et 3 000 €',
  2: 'entre 3 001 € et 5 000 €',
  3: 'entre 5 001 € et 8 000 €',
  4: 'supérieurs ou égal à 8 001 €'
}

export const financialAsset = {
  0: 'Moins de 10 000 €',
  1: 'Entre 10 000 € et 50 000 €',
  2: 'Entre 50 000 € et 100 000 €',
  3: 'Entre 100 000 € et 200 000 € ',
  4: 'Entre 200 000 € et 350 000 € ',
  5: 'Entre 350 000 € et 500 000 € ',
  6: 'Plus de 500 000 ',
}

export const financialAssetRecap = {
  0: 'à moins de 10 000 €',
  1: 'entre 10 000 € et 50 000 €',
  2: 'entre 50 000 € et 100 000 €',
  3: 'entre 100 000 € et 200 000 € ',
  4: 'entre 200 000 € et 350 000 € ',
  5: 'entre 350 000 € et 500 000 € ',
  6: 'supérieur ou égal à 500 000 €'
}

export const realEstateAsset = {
  0: 'Moins de 10 000 €',
  1: 'Entre 10 000 € et 50 000 €',
  2: 'Entre 50 000 € et 100 000 €',
  3: 'Entre 100 000 € et 200 000 € ',
  4: 'Entre 200 000 € et 350 000 € ',
  5: 'Entre 350 000 € et 500 000 € ',
  6: 'Plus de 500 000 ',
}

export const sourceOfFunds = {
  0: "Revenus et épargne personnelle",
  1: "Revenus de placements financiers",
  2: "Vente de biens immobiliers",
  3: "Vente de biens mobiliers",
  4: "Héritage et donation",
  5: "Autres",
  6: "Prêts et emprunts",
  7: "Indemnités et dommages-intérêts",
  8: "Gains de jeux"
};

export const sourceOfFundsRecap = {
  0: "des revenus et épargne personnelle",
  1: "des revenus de placements financiers",
  2: "d'une vente de biens immobiliers",
  3: "d'une vente de biens mobiliers",
  4: "d'un héritage ou d'une donation",
  5: "autres",
  6: "de prêts et emprunts",
  7: "d'indemnités ou de dommages-intérêts",
  8: "de gains de jeux"
};

export const employmentStatus = {
  0: "Salariés du privé",
  1: "Salariés du public",
  2: "Non salarié",
  3: "Retraité",
  4: "Sans emploi"
}

export const occupation = {
  0: { label: 'Apprenti', employmentStatus: [0, 1] },
  1: { label: 'CDD', employmentStatus: [0, 1] },
  2: { label: 'CDI', employmentStatus: [0, 1] },
  3: { label: 'Sans contrat de travail', employmentStatus: [2] },
  4: { label: 'Intérimaire', employmentStatus: [0, 1, 2] },
}

export const natureRelation = {
  1: 'Mère / Père',
  2: 'Conjoint / Conjointe de fait',
  3: 'Enfant',
  4: "Conjoint d'un enfant (gendre / belle-fille)",
  5: 'Autre',
  6: 'Associé proche',
};

export const documentType = {
  0: "JDI",
  1: "JDIR",
  2: "JDIV",
  3: "JDD",
  4: "IB",
  5: "JDR",
  6: "JDIH",
  7: "JDIHR",
  8: "JDIHV",
  9: "JDDH"
}

export const getMaritalStatus = (status) => {
  if (status === undefined || status === null)
    return '';
  return maritalStatus[status]
}

export const getEmploymentStatus = (status) => {
  if (status === undefined || status === null)
    return '';
  return employmentStatus[status]
}

export const getOccupation = (role) => {
  if (role === undefined || role === null)
    return '';
  return occupation[role].label
}

export const getPoliticalExhibition = (isExposed = false) => {
  return isExposed ? "vous êtes une personne exposée." : "vous n'êtes pas une personne exposée"
}

export const getMonthlyIncome = (key) => {
  if (key === undefined || key === null)
    return ''
  return monthlyIncome[key]
}

export const getFinancialAsset = (key) => {
  if (key === undefined || key === null)
    return '';
  return financialAssetRecap[key];
}

export const getSourceOfFund = (key) => {
  if (key === undefined || key === null)
    return '';
  return sourceOfFundsRecap[key];
}

export const getDocumentType = (key) => {
  if (key === undefined || key === null)
    return '';
  return documentType[key];
}
export const civility = { Mme: 'Madame', M: 'Monsieur' }